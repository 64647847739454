import {useMutation, useQuery, useQueryClient} from 'react-query'
import axios from 'axios'

import {IUser} from 'shared/consts'

export const useUser = () => {
    return useQuery<IUser>(['useUser'], async () => {
        return await getUser()
    })
}

export const useIsAdmin = () => {
    const {data: user} = useUser()

    return user?.isAdmin || false
}

export const useSetUser = () => {
    const queryClient = useQueryClient()

    return useMutation<unknown, unknown, string>(
        async pass => {
            return await setUser(pass)
        },
        {
            onSuccess: data => {
                queryClient.setQueryData(['useUser'], data)
                queryClient.invalidateQueries('useBookingsBrows')
                queryClient.invalidateQueries('useHours')
            },
        },
    )
}

export const useSetPhone = () => {
    const queryClient = useQueryClient()

    return useMutation<unknown, unknown, string>(
        async phone => {
            return await setPhone(phone)
        },
        {
            onSuccess: data => {
                queryClient.setQueryData(['useUser'], data)
                queryClient.invalidateQueries('useBookingsBrows')
                queryClient.invalidateQueries('useHours')
            },
        },
    )
}

const getUser = async (): Promise<IUser> => {
    const {data} = await axios({
        method: 'get',
        url: '/api/v1/user',
    })

    return data
}

const setUser = async (pass: string): Promise<void> => {
    const {data} = await axios({
        method: 'post',
        url: '/api/v1/user',
        data: {password: pass},
    })

    return data
}

const setPhone = async (phone: string): Promise<void> => {
    const {data} = await axios({
        method: 'post',
        url: '/api/v1/user',
        data: {phone},
    })

    return data
}
