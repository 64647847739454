import React, {useCallback, useState} from 'react'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import {sendEvent} from 'front/frontUtils'
import {Memo} from 'front/components/memo/Memo'

import styles from './faq.css'

export function Faq() {
    const [expanded, setExpanded] = useState(null)

    const expand = useCallback(
        (isExpanded: boolean, id: number) => {
            sendEvent('faq')
            setExpanded(isExpanded ? id : null)
        },
        [setExpanded, sendEvent],
    )

    return (
        <div>
            <Accordion
                expanded={expanded === 1}
                onChange={(e, isExpanded) => expand(isExpanded, 1)}
                itemScope
                itemType='http://schema.org/Question'
            >
                <AccordionSummary className={styles.accordionItem} expandIcon={<ExpandMore />}>
                    <div className={styles.accordionName} itemProp='name'>
                        Как записаться на свадебный образ?
                    </div>
                </AccordionSummary>
                <AccordionDetails itemProp='acceptedAnswer' itemScope itemType='http://schema.org/Answer'>
                    <div className={styles.answer} itemProp='text'>
                        <ul className={styles.list}>
                            <li>
                                Вы оставляете заявку здесь или пишите мне в удобном вам мессенджере, уточняете, свободна
                                ли дата
                            </li>
                            <li>
                                Дальше мы обсуждаем с вами время, к которому вам нужно быть готовой, удобны ли сборы в
                                студии или необходим выезд
                            </li>
                            <li>Обговариваем образ, другие детали, я бронирую за вами дату и время</li>
                            <li>Если необходимо, проводим репетицию</li>
                            <li>Отвечаю на ваши вопросы и даю рекомендации по подготовке</li>
                            <li>Создаю образ в день торжества</li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 2}
                onChange={(e, isExpanded) => expand(isExpanded, 2)}
                itemScope
                itemType='http://schema.org/Question'
            >
                <AccordionSummary className={styles.accordionItem} expandIcon={<ExpandMore />}>
                    <div className={styles.accordionName} itemProp='name'>
                        Что такое репетиция образа и зачем она нужна?
                    </div>
                </AccordionSummary>
                <AccordionDetails itemProp='acceptedAnswer' itemScope itemType='http://schema.org/Answer'>
                    <div className={styles.answer} itemProp='text'>
                        <span>
                            Репетиция необязательна. Мы можем обговорить все нюансы образа на предварительной
                            консультации.
                        </span>
                        <span>Я рекомендую проводить репетицию, если:</span>
                        <ul className={styles.list}>
                            <li>вы не знаете, какой образ хотите, и не понимаете, что вам подойдет</li>
                            <li>
                                вы никогда не были у стилиста и боитесь, что вам будет непривычно в день торжества в
                                новом для вас образе
                            </li>
                            <li>хотите заранее лично познакомиться с мастером</li>
                        </ul>
                        <span>Репетиция занимает около 2-3 часов. Проходит в студии в центре города.</span>
                        <span>Мы проработаем образ, который обсудим заранее на консультации.</span>
                        <span>
                            Можете совместить репетицию с девичником, фотосессией или свиданием с будущим мужем😊
                        </span>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 3}
                onChange={(e, isExpanded) => expand(isExpanded, 3)}
                itemScope
                itemType='http://schema.org/Question'
            >
                <AccordionSummary className={styles.accordionItem} expandIcon={<ExpandMore />}>
                    <div className={styles.accordionName} itemProp='name'>
                        Что такое сопровождение?
                    </div>
                </AccordionSummary>
                <AccordionDetails itemProp='acceptedAnswer' itemScope itemType='http://schema.org/Answer'>
                    <div className={styles.answer} itemProp='text'>
                        <div>
                            Я проведу с вами весь свадебный день: буду контролировать свежесть макияжа, поправлять
                            прическу, если это необходимо, следить, чтобы лицо не блестело при жаркой погоде и т.п.
                        </div>
                        <div>
                            Это важно при фото- и видеосъемках. Так гримеры проводят весь день с актерами на съемочной
                            площадке😊
                        </div>
                        <div>
                            По желанию мы можем сменить образ в течение дня: например, сделать из локонов собранную
                            прическу или добавить в макияж яркие акценты.
                        </div>
                        <div>
                            Вы можете взять сопровождение не на весь день, а на несколько часов. Например, на самую
                            активную и торжественную часть праздника.
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 4}
                onChange={(e, isExpanded) => expand(isExpanded, 4)}
                itemScope
                itemType='http://schema.org/Question'
            >
                <AccordionSummary className={styles.accordionItem} expandIcon={<ExpandMore />}>
                    <div className={styles.accordionName} itemProp='name'>
                        Сколько времени нужно на создание образа?
                    </div>
                </AccordionSummary>
                <AccordionDetails itemProp='acceptedAnswer' itemScope itemType='http://schema.org/Answer'>
                    <div className={styles.answer} itemProp='text'>
                        {
                            'Как правило, сборы занимают около 2 - 2.5 часов в зависимости от сложности макияжа и прически, густоты волос и т.д.'
                        }
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 5}
                onChange={(e, isExpanded) => expand(isExpanded, 5)}
                itemScope
                itemType='http://schema.org/Question'
            >
                <AccordionSummary className={styles.accordionItem} expandIcon={<ExpandMore />}>
                    <div className={styles.accordionName} itemProp='name'>
                        Как подготовиться к свадебным сборам?
                    </div>
                </AccordionSummary>
                <AccordionDetails itemProp='acceptedAnswer' itemScope itemType='http://schema.org/Answer'>
                    <div itemProp='text'>
                        <Memo />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
