import React, {useCallback, useEffect, useMemo, useState} from 'react'
import ExpandMore from '@mui/icons-material/ExpandMore'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

import {EPriceListType} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {useSettings} from 'front/queries/settings'
import {useCountry} from 'front/hooks/useCountry'

import styles from './priceList.css'

export function PriceList(props: IProps) {
    const {type, event} = props
    const {data: settings} = useSettings()
    const country = useCountry()

    const [region, setRegion] = useState<TRegions>('all')
    const [expanded, setExpanded] = useState(null)
    const sendEventCb = useCallback(() => {
        if (event) {
            sendEvent(event)
        }
    }, [event, sendEvent])

    const priceList = useMemo(() => {
        if (!settings?.priceList) {
            return null
        }

        return settings.priceList[country][type]
    }, [settings, country, type])

    const list = useMemo(() => {
        if (!priceList?.[region]) {
            return null
        }

        return priceList[region].map(item => (
            <Accordion
                key={item.id}
                expanded={expanded === item.id}
                onChange={(e, isExpanded) => {
                    sendEventCb()

                    setExpanded(isExpanded ? item.id : null)
                }}
            >
                <AccordionSummary className={styles.accordionItem} expandIcon={<ExpandMore />}>
                    <div className={styles.accordionContent}>
                        <div className={styles.accordionTop}>
                            <div className={styles.accordionName}>{item.name}</div>
                            <div className={styles.accordionPrice}>{item.price.replace(/ /g, ' ')}</div>
                        </div>
                        {item.nameLevel2 && <div className={styles.accordionLevel2}>{item.nameLevel2}</div>}
                        {item.nameLevel3 && <div className={styles.accordionLevel3}>{item.nameLevel3}</div>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={1}>
                        {item.duration && (
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Typography variant='subtitle2'>Длительность:</Typography>
                                <Typography>{item.duration}</Typography>
                            </Stack>
                        )}
                        {item.includes && (
                            <div>
                                <Typography variant='subtitle2'>В стоимость включено:</Typography>
                                <ul className={styles.list}>
                                    {item.includes.map(text => (
                                        <li key={text}>{text}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {item.comments && (
                            <div>
                                <Typography variant='subtitle2'>Дополнительно:</Typography>
                                <ul className={styles.list}>
                                    {item.comments.map(text => (
                                        <li key={text}>{text}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        ))
    }, [priceList, setExpanded, expanded, region])

    useEffect(() => {
        if (!priceList) {
            return
        }

        const isSingleRegion = Object.keys(priceList).length === 1

        setRegion(isSingleRegion ? 'all' : 'moscow')
    }, [priceList])

    useEffect(() => {
        setExpanded(null)
    }, [region])

    if (!priceList) {
        return null
    }

    return (
        <div>
            <TabContext value={region}>
                {region !== 'all' && (
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList
                            className={styles.tabList}
                            onChange={(e, value) => {
                                sendEventCb()
                                setRegion(value)
                            }}
                        >
                            <Tab className={styles.tab} label='Москва' value='moscow' />
                            <Tab className={styles.tab} label='Коломна' value='kolomna' />
                        </TabList>
                    </Box>
                )}
                <div className={styles.tabPanel}>{list}</div>
            </TabContext>
        </div>
    )
}

interface IProps {
    type: EPriceListType
    event?: string
}

type TRegions = 'all' | 'moscow' | 'kolomna'
