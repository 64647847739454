import React from 'react'
import {useNavigate} from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import styles from './header.css'

export function Header(props: IProps) {
    const {text, onBack, href, subText} = props
    const navigate = useNavigate()

    return (
        <div
            className={styles.header}
            onClick={() => {
                if (onBack) {
                    onBack()
                } else if (href) {
                    navigate(href)
                } else {
                    navigate(-1)
                }
            }}
        >
            <Button
                className={styles.bakcBtn}
                // edge='start'
                color='primary'
                variant='contained'
                aria-label='back'
                // onClick={onBack}
            >
                <ArrowBackIcon />
            </Button>
            <Stack>
                <div className={styles.mainText}>{text}</div>
                {subText && <div className={styles.subText}>{subText}</div>}
            </Stack>
        </div>
    )
}

interface IProps {
    onBack?: () => void
    href?: string
    text: string
    subText?: string
}
