import {useMutation, useQuery, useQueryClient} from 'react-query'
import axios from 'axios'
import moment from 'moment'

import {DATE_FORMAT, ERegions, IHour, IInterval} from 'shared/consts'

export const useHours = () => {
    return useQuery(['useHours'], async () => {
        const hours = await getHours()
        const res: Record<string, Record<string, IInterval[]>> = {}

        for (const {date, interval, region} of hours) {
            const dateStr = moment(date * 1000).format(DATE_FORMAT)
            let currentRegion = res[region]

            if (!currentRegion) {
                currentRegion = res[region] = {}
            }

            let currentDay = currentRegion[dateStr]

            if (!currentDay) {
                currentDay = currentRegion[dateStr] = []
            }

            currentDay.push(interval)
        }

        return res
    })
}

export const useAddHours = () => {
    const queryClient = useQueryClient()

    return useMutation<unknown, unknown, {date: number; intervals: IInterval[]; region: ERegions}>(
        async params => {
            const {date, intervals, region} = params

            await addHours(date, intervals, region)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useHours')
            },
        },
    )
}

const getHours = async (): Promise<IHour[]> => {
    const {data} = await axios({
        method: 'get',
        url: '/api/v1/hours',
    })

    return data
}

const addHours = async (date: number, intervals: IInterval[], region: ERegions): Promise<void> => {
    await axios({
        method: 'post',
        url: '/api/v1/hours',
        data: {date, intervals, region},
    })
}

/* const deleteItems = async (from: number, to: number) => {
    await axios({
        method: 'delete',
        url: '/api/v1/hours',
        data: {from, to}
    });
}; */
