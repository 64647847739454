import React from 'react'
import {useNavigate} from 'react-router-dom'
import image from 'assets/images/pages/brows.jpg'
import image1 from 'assets/images/pages/brows/brows1.jpg'
import image2 from 'assets/images/pages/brows/brows2.jpg'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import {EPriceListType} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {Comments} from 'front/components/comments/Comments'
import {Header} from 'front/components/header/Header'
import {Image} from 'front/components/image/Image'
import {PriceList} from 'front/components/priceList/PriceList'

import styles from './browsPage.css'

export function BrowsPage() {
    const navigate = useNavigate()

    return (
        <Stack className={styles.browsPage} spacing={1}>
            <Header text={'Оформление бровей'} href='/' />
            <Stack spacing={4}>
                <Stack spacing={2}>
                    <Image src={image} width={1024} height={1370}>
                        <div className={styles.imageForegroundLow} />
                        <Button
                            className={styles.imageButton}
                            color='secondary'
                            variant='outlined'
                            onClick={() => {
                                sendEvent('browsBookBtn0')
                                navigate('/booking/brows')
                            }}
                        >
                            Записаться
                        </Button>
                    </Image>
                    <div className={styles.text}>
                        <div>Сделаю желаемую форму бровей с естественным и стойким окрашиванием:</div>
                        <ul className={styles.list}>
                            <li>на коже до 7 - 10 дней</li>
                            <li>на волосках до 5 недель</li>
                        </ul>
                        <div className={styles.label}>О процедуре</div>
                        <div>
                            Оформление бровей – это бьюти-процедура, которая улучшает природную форму и цвет. Ухоженные
                            и симметричные брови делают взгляд более выразительным, а образ – завершенным. Однако данная
                            процедура требует регулярности. Вы можете осуществить ее и дома, но результат будет сильно
                            отличаться от работы мастера.
                        </div>
                        <div>
                            <span className={styles.strong}>Коррекция и окрашивание бровей</span> – это не только
                            удаление нежелательных волосков и окрашивание.
                        </div>
                        <div className={styles.label}>В услугу входит:</div>
                        <ul className={styles.list}>
                            <li>построение формы</li>
                            <li>архитектура (подбор формы брови под особенности вашего лица)</li>
                            <li>колористика (создание градиентов и переходов цвета)</li>
                            <li>уход за бровями</li>
                        </ul>
                        <Divider className={styles.hidden} />
                        <div>
                            Также я все чаще рекомендую своим клиентам{' '}
                            <span className={styles.strong}>процедуру долговременной укладки бровей</span>, так как она:
                        </div>
                        <ul className={styles.list}>
                            <li>создает эффект максимально ухоженных бровей</li>
                            <li>возвращает бровям природную форму</li>
                            <li>позволяет задать направление волоскам, делает их послушными</li>
                            <li>при регулярных процедурах дает возможность отрастить недостающие волоски</li>
                            <li>облегчает укладку, если вы пользуетесь гелями для бровей в ежедневном макияже</li>
                        </ul>
                    </div>
                    <div className={styles.galery}>
                        <Image src={image1} width={500} height={760} />
                        <Image src={image2} width={393} height={597} />
                    </div>
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('browsBookBtn1')
                            navigate('/booking/brows')
                        }}
                    >
                        Записаться
                    </Button>
                    <Divider />
                </Stack>
                <Stack spacing={2}>
                    <div className={styles.header}>Услуги и цены</div>
                    <PriceList type={EPriceListType.brows} event='priceBrows' />
                    <Divider />
                </Stack>
                <Stack spacing={2}>
                    <div className={styles.header}>Отзывы</div>
                    <Comments type='brows' event='commentsBrows' />
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('browsBookBtn2')
                            navigate('/booking/brows')
                        }}
                    >
                        Записаться
                    </Button>
                    <Divider />
                </Stack>
            </Stack>
        </Stack>
    )
}
