import React, {useEffect, useMemo, useState} from 'react'
import capitalize from 'lodash/capitalize'
import intersection from 'lodash/intersection'
import moment from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import {
    COLORS,
    EBookingTypes,
    ERegions,
    ESettings,
    IBooking,
    IInterval,
    MAX_BOOKING_TIME,
    SERVICES_ITEMS,
} from 'shared/consts'
import {pluralize} from 'shared/utils'
import {useBrowsBooking, useDeleteBooking} from 'front/queries/bookings'
import {useSettings} from 'front/queries/settings'
import {useIsAdmin, useUser} from 'front/queries/user'
import {useCountry, usePrice} from 'front/hooks/useCountry'
import {Header} from 'front/components/header/Header'
import {Phone} from 'front/components/phone/Phone'

import styles from './bookingForm.css'

export function BookingForm(props: IProps) {
    const {date, interval, region} = props
    const {data: booking, isLoading: isBookingLoading} = useBrowsBooking(date, interval, region)
    const {data: settings} = useSettings()
    const country = useCountry()
    const mDate = moment(date * 1000)
    const isEdit = !!booking
    const isAdmin = useIsAdmin()
    const canEdit = !isEdit || isAdmin

    const showDeleteButton = useMemo(() => {
        if (!booking || !settings) {
            return false
        }

        const [hour, minute] = booking.interval.from.split(':').map(Number)
        const lastTime = moment().add(settings[ESettings.maxUnbookingTime], 'hour').unix()
        const bookDate = moment(booking.date * 1000)
            .set({hour, minute})
            .unix()

        return booking && settings && (isAdmin || lastTime <= bookDate)
    }, [booking, settings, isAdmin])

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [phone, setPhone] = useState('')
    const [comment, setComment] = useState('')
    const [services, setServices] = useState<string[]>([])

    const [showSelect, setShowSelect] = useState<boolean>(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    const {data: user, isLoading: isUserLoading} = useUser()
    const {mutate: deleteBooking} = useDeleteBooking()

    const currentList = useMemo(() => {
        if (!settings) {
            return null
        }

        return settings.priceList[country].brows[region] || settings.priceList[country].brows['all']
    }, [settings, region, country])

    const price = useMemo(() => {
        if (!currentList) {
            return 0
        }

        let sum = 0

        for (const id of services) {
            const service = SERVICES_ITEMS.find(item => item.id === id)

            if (intersection(services, service.priceSummWith).length === 0) {
                const serviceData = currentList.find(item => item.id === service.priceId)
                sum += Number(serviceData?.price.match(/\d/g).join('')) || 0
            }
        }

        return sum
    }, [services, currentList])

    const currencyPrice = usePrice(price)

    useEffect(() => {
        if (isBookingLoading || isUserLoading) {
            return
        }

        if (user?.phone) {
            setPhone(user.phone)
        }

        if (booking) {
            setName(booking.name)
            setSurname(booking.surname)
            setPhone(booking.phone)
            setComment(booking.comment)
            setServices(booking.services)
        }
    }, [booking, user, isUserLoading, isBookingLoading])

    return (
        <Stack className={styles.bookingForm} spacing={2}>
            <Header
                text={`${capitalize(mDate.format('ddd'))}, ${mDate.format('DD MMMM')}, ${interval.from}-${interval.to}`}
                onBack={props.onCancel}
            />
            <FormControl size='small' color='primary'>
                <InputLabel required>Услуги</InputLabel>
                <Select
                    multiple
                    open={showSelect}
                    onOpen={() => {
                        setShowSelect(true)
                    }}
                    onClose={() => {
                        setShowSelect(false)
                    }}
                    value={services}
                    onChange={e => {
                        setServices((e.target.value as string[]).filter(Boolean))
                    }}
                    input={<OutlinedInput label='Услуги' />}
                    renderValue={selected => {
                        return `Выбран${pluralize(selected.length, ['а', 'ы', 'ы'])} ${
                            selected.length
                        } услуг${pluralize(selected.length, ['а', 'и', ''])}`
                    }}
                    MenuProps={{
                        MenuListProps: {
                            style: {
                                padding: '8px 15px',
                            },
                        },
                    }}
                >
                    {SERVICES_ITEMS.map(({id, name, type, disableIf, priceId}) => {
                        if (type === 'label') {
                            return (
                                <div key={id}>
                                    <div className={styles.boxesHeader}>{name}</div>
                                    <Divider />
                                </div>
                            )
                        }

                        const serviceData = currentList?.find(item => item.id === priceId)

                        if (type === 'box') {
                            return (
                                <MenuItem
                                    className={styles.select}
                                    key={id}
                                    value={id}
                                    disabled={
                                        (serviceData && !serviceData?.price) ||
                                        !canEdit ||
                                        intersection(services, disableIf).length > 0
                                    }
                                    sx={{
                                        padding: 0,
                                    }}
                                >
                                    <Checkbox
                                        sx={{padding: '0 5px 0 0'}}
                                        color='primary'
                                        checked={services.includes(id)}
                                    />
                                    {name}
                                </MenuItem>
                            )
                        }
                    })}
                    <div className={styles.selectFooter}>
                        <Button
                            color='primary'
                            variant='contained'
                            className={styles.selectBtn}
                            onClick={() => {
                                setShowSelect(false)
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </Select>
            </FormControl>
            <TextField
                color='primary'
                disabled={!canEdit}
                required
                label='Имя'
                variant='outlined'
                size='small'
                inputProps={{
                    maxLength: 50,
                }}
                value={name}
                onChange={e => {
                    setName(e.target.value)
                }}
            />
            <TextField
                color='primary'
                disabled={!canEdit}
                label='Фамилия'
                variant='outlined'
                size='small'
                inputProps={{
                    maxLength: 50,
                }}
                value={surname}
                onChange={e => {
                    setSurname(e.target.value)
                }}
            />
            <Phone disabled={!canEdit || (!isAdmin && Boolean(user?.phone))} setPhone={setPhone} phone={phone} />
            <TextField
                color='primary'
                disabled={!canEdit}
                label='Комментарий'
                variant='outlined'
                size='small'
                inputProps={{
                    maxLength: 512,
                }}
                multiline
                minRows={2}
                maxRows={4}
                value={comment}
                onChange={e => {
                    setComment(e.target.value)
                }}
            />
            <Stack className={styles.footer} spacing={1.5} direction='row'>
                {canEdit && (
                    <Button
                        disabled={!name.trim() || phone.length !== 11 || services.length === 0}
                        sx={{
                            maxWidth: isEdit ? 120 : 150,
                        }}
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            props.onSubmit({
                                id: booking?.id,
                                date,
                                region,
                                interval,
                                name: name.trim(),
                                surname: surname.trim(),
                                phone,
                                services,
                                comment: comment.trim(),
                                type: EBookingTypes.brows,
                            })
                        }}
                    >
                        {isEdit ? 'Сохранить' : 'Записаться'}
                    </Button>
                )}
                {showDeleteButton && (
                    <Button
                        className={styles.deleteBtn}
                        color='primary'
                        sx={{
                            backgroundColor: COLORS.colorBrown,
                        }}
                        variant='contained'
                        aria-label='delete'
                        onClick={() => {
                            setOpenDeleteDialog(true)
                        }}
                    >
                        <DeleteIcon />
                    </Button>
                )}
                {price > 0 && (
                    <div className={styles.price}>
                        <span>Стоимость: </span>
                        <span>{currencyPrice}</span>
                    </div>
                )}
            </Stack>
            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false)
                }}
            >
                <DialogTitle>Отменить запись?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите отменить запись? Помните, что запись возможна только за{' '}
                        {MAX_BOOKING_TIME} час{pluralize(MAX_BOOKING_TIME, ['', 'a', 'ов'])}.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            setOpenDeleteDialog(false)
                        }}
                    >
                        Нет
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            deleteBooking({booking, type: EBookingTypes.brows}, {onSuccess: props.onCancel})
                        }}
                    >
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

interface IProps {
    date: number
    region: ERegions
    interval: IInterval
    onSubmit: (booking: IBooking, id?: number) => void
    onCancel: () => void
}
