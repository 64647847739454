import React, {useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'

import {ERegions, IBooking, IMAGES, METHODS, REGIONS} from 'shared/consts'
import {getPhoneString} from 'shared/utils'
import {useAddBooking, useBookingsImage} from 'front/queries/bookings'
import {useIsAdmin} from 'front/queries/user'
import {BookingImageForm} from 'front/components/bookingImageForm/BookingImageForm'
import {CitySelect} from 'front/components/citySelect/CitySelect'
import {Header} from 'front/components/header/Header'

import styles from './adminImagePage.css'

export function AdminImagePage() {
    const navigate = useNavigate()
    const isAdmin = useIsAdmin()

    const {mutate: addBooking} = useAddBooking()
    const {data: allBookings} = useBookingsImage(isAdmin)

    const [region, setRegion] = useState<ERegions>(ERegions.all)
    const [actual, setActual] = useState<TActual>('actual')
    const [now] = useState(Math.round(Date.now() / 1000))
    const [currentBookingId, setCurrentBookingId] = useState<number>(null)

    const bookings = useMemo(() => {
        if (!allBookings?.length) {
            return null
        }

        const res = allBookings
            .filter(item => region === 'all' || item.region === region)
            .filter(item => actual === 'all' || item.date > now)

        return res
    }, [allBookings, region, actual, now])

    const currentBooking = useMemo(() => {
        if (!bookings?.length || !currentBookingId) {
            return null
        }

        return bookings.find(item => item.id === currentBookingId)
    }, [bookings, currentBookingId])

    const list = useMemo(() => {
        if (!bookings?.length) {
            return <div className={styles.empty}>Нет записей</div>
        }

        return bookings
            .sort((a, b) => a.date - b.date)
            .map(booking => {
                return (
                    <Stack
                        spacing={1}
                        key={booking.id}
                        onClick={() => {
                            setCurrentBookingId(booking.id)
                        }}
                    >
                        <div className={styles.itemLabel}>
                            {moment(booking.date * 1000).format('DD MMM')} {booking.name} {booking.surname}
                        </div>
                        <div>
                            <span className={styles.subLabel}>Телефон:</span> {getPhoneString(booking.phone)}
                        </div>
                        <div>
                            <span className={styles.subLabel}>Город:</span> {REGIONS[booking.region]}
                        </div>
                        <div>
                            <span className={styles.subLabel}>Услуга:</span> {IMAGES[booking.imageType]}
                        </div>
                        <div>
                            <span className={styles.subLabel}>Способ связи:</span> {METHODS[booking.method]}
                        </div>
                        <div className={styles.itemComment}>
                            <span className={styles.subLabel}>Комментарий:</span> {booking.comment}
                        </div>
                    </Stack>
                )
            })
    }, [bookings])

    if (!isAdmin) {
        return null
    }

    return (
        <Stack className={styles.adminImagePage} spacing={2}>
            <Header
                text={currentBooking ? 'Редактирование записи' : 'Список записей'}
                onBack={() => {
                    if (currentBooking) {
                        setCurrentBookingId(null)
                    } else {
                        navigate(-1)
                    }
                }}
            />
            {!currentBooking ? (
                <>
                    <Stack direction='row' spacing={2}>
                        <CitySelect hasAll city={region} setCity={setRegion} sx={{width: '50%'}} />
                        <FormControl size='small' sx={{width: '50%'}}>
                            <InputLabel id='actual-select-label'>Дата</InputLabel>
                            <Select
                                labelId='actual-select-label'
                                value={actual}
                                label='Дата'
                                onChange={e => {
                                    setActual(e.target.value as TActual)
                                }}
                            >
                                <MenuItem value={'all'}>Все</MenuItem>
                                <MenuItem value={'actual'}>Актуальные</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    {list}
                </>
            ) : (
                <BookingImageForm
                    canDelete={isAdmin}
                    booking={currentBooking}
                    onCancel={() => {
                        setCurrentBookingId(null)
                    }}
                    onSubmit={(booking: IBooking) => {
                        addBooking(booking, {
                            onSuccess: () => {
                                setCurrentBookingId(null)
                            },
                        })
                    }}
                />
            )}
        </Stack>
    )
}

type TActual = 'actual' | 'all'
