import React from 'react'
import capitalize from 'lodash/capitalize'
import moment from 'moment'

import {ERegions, IInterval} from 'shared/consts'
import {useIsAdmin} from 'front/queries/user'

import {HoursTable} from '../hoursTable/HoursTable'
import styles from './day.css'

export function Day(props: IProps) {
    const {date, intervals, onEdit, onIntervalClick, region} = props
    const mDate = moment(date * 1000)
    const isAdmin = useIsAdmin()

    return (
        <div className={styles.day}>
            <div className={styles.name}>
                {capitalize(mDate.format('dd'))} ({mDate.format('D MMM').slice(0, -1)}):
            </div>
            {(isAdmin || intervals.length > 0) && (
                <HoursTable
                    small
                    region={region}
                    canEdit={isAdmin}
                    date={date}
                    intervals={intervals}
                    activeIntervals={[]}
                    onClick={item => {
                        onIntervalClick?.(item)
                    }}
                    onEdit={() => {
                        onEdit(date)
                    }}
                />
            )}
            {!isAdmin && intervals.length === 0 && <div className={styles.disclaimer}>Нет свободных мест</div>}
        </div>
    )
}

interface IProps {
    date: number
    region: ERegions
    intervals: IInterval[]
    onEdit?: (date: number) => void
    onIntervalClick?: (interval: IInterval) => void
}
