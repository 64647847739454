import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import imageTop from 'assets/video/imageTop.mp4'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import {EPriceListType} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {usePortfolios} from 'front/queries/portfolio'
import {Comments} from 'front/components/comments/Comments'
import {Header} from 'front/components/header/Header'
import {PriceList} from 'front/components/priceList/PriceList'
import {Slider} from 'front/components/slider/Slider'

import styles from './imagePage.css'

export function ImagePage() {
    const navigate = useNavigate()

    const {data: portfolios} = usePortfolios()

    const portfolio = useMemo(() => {
        if (!portfolios) {
            return null
        }

        return portfolios.find(item => item.name === 'image')
    }, [portfolios])

    return (
        <Stack className={styles.imagePage} spacing={1}>
            <Header text={'Вечерний и дневной образ'} href='/' />
            <Stack spacing={4}>
                <Stack spacing={2}>
                    <video className={styles.video} autoPlay loop muted preload='yes' playsInline disableRemotePlayback>
                        <source src={imageTop} type='video/mp4' />
                    </video>
                    <Stack className={styles.text} spacing={1}>
                        <div>
                            Легкий nude и естественные локоны, вечерние smoky и гладкие прически, голливудская классика
                            с красными губами и стрелками или яркий цветной макияж и текстурные волосы - правил и границ
                            не существует.
                        </div>
                        <div>
                            Я создам стильный актуальный образ, который будет подходить под ваше настроение, концепцию
                            мероприятия или съемки.
                        </div>
                    </Stack>
                    {portfolio && (
                        <Slider
                            images={portfolio.images.map(imageId => `/api/v1/images/${imageId}`)}
                            event='sliderImage'
                        />
                    )}
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('imageBookBtn1')
                            navigate('/booking/image')
                        }}
                    >
                        Записаться
                    </Button>
                    <Divider />
                </Stack>
                <Stack spacing={2}>
                    <div className={styles.header}>Услуги и цены</div>
                    <PriceList type={EPriceListType.image} event='priceImage' />
                    <Divider />
                </Stack>
                <Stack spacing={2}>
                    <div className={styles.header}>Отзывы</div>
                    <Comments type='image' event='commentsImage' />
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('imageBookBtn2')
                            navigate('/booking/image')
                        }}
                    >
                        Записаться
                    </Button>
                    <Divider />
                </Stack>
            </Stack>
        </Stack>
    )
}
