import 'front/styles/index.css'

import React from 'react'
import {QueryClient, QueryClientProvider} from 'react-query'
import {Provider} from 'react-redux'
import {EnhancedStore} from '@reduxjs/toolkit'
import AdapterDateFns from '@mui/lab/AdapterMoment'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'

import {Layout} from 'front/layout/Layout'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            retry: false,
        },
    },
})

export function App(props: {store: EnhancedStore}) {
    return (
        <React.StrictMode>
            <Provider store={props.store}>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Layout />
                    </LocalizationProvider>
                </QueryClientProvider>
            </Provider>
        </React.StrictMode>
    )
}
