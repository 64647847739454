import React, {useEffect} from 'react'
// import {lazy, Suspense} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'

import AboutPage from 'front/pages/about'
import AdminPage from 'front/pages/admin'
import AdminFeedbackPage from 'front/pages/adminFeedback'
import AdminImagePage from 'front/pages/adminImage'
import AdminPortfolioPage from 'front/pages/adminPortfolio'
import AdminPricePage from 'front/pages/adminPrice'
import AdminTrainingPage from 'front/pages/adminTraining'
import AirbrushPage from 'front/pages/airbrush'
import BookingPage from 'front/pages/booking'
import BookingBrowsPage from 'front/pages/bookingBrows'
import BookingImagePage from 'front/pages/bookingImage'
import BookingTrainingPage from 'front/pages/bookingTraining'
import BrowsPage from 'front/pages/brows'
import ContactsPage from 'front/pages/contacts'
import FeedbackPage from 'front/pages/feedback'
import ImagePage from 'front/pages/image'
import MainPage from 'front/pages/main'
import MemoPage from 'front/pages/memo'
import PortfolioPage from 'front/pages/portfolio'
import TrainingPage from 'front/pages/training'
import WeddingPage from 'front/pages/wedding'
import {useShowSections} from 'front/hooks/useCountry'

// const MainPage = lazy(() => import(
//     /* webpackChunkName: "pages/MainPage" */
//     'front/pages/main'
// ));

// const BookingBrowsPage = lazy(() => import(
//     /* webpackChunkName: "pages/BookingBrowsPage" */
//     'front/pages/bookingBrows'
// ));

// const BookingImagePage = lazy(() => import(
//     /* webpackChunkName: "pages/BookingImagePage" */
//     'front/pages/bookingImage'
// ));

// const BookingTrainingPage = lazy(() => import(
//     /* webpackChunkName: "pages/BookingTrainingPage" */
//     'front/pages/bookingTraining'
// ));

// const BookingPage = lazy(() => import(
//     /* webpackChunkName: "pages/BookingPage" */
//     'front/pages/booking'
// ));

// const PortfolioPage = lazy(() => import(
//     /* webpackChunkName: "pages/PortfolioPage" */
//     'front/pages/portfolio'
// ));

// const AdminPage = lazy(() => import(
//     /* webpackChunkName: "pages/AdminPage" */
//     'front/pages/admin'
// ));

// const AdminFeedbackPage = lazy(() => import(
//     /* webpackChunkName: "pages/AdminFeedbackPage" */
//     'front/pages/adminFeedback'
// ));

// const AdminImagePage = lazy(() => import(
//     /* webpackChunkName: "pages/AdminImagePage" */
//     'front/pages/adminImage'
// ));

// const AdminTrainingPage = lazy(() => import(
//     /* webpackChunkName: "pages/AdminTrainingPage" */
//     'front/pages/adminTraining'
// ));

// const AdminPortfolioPage = lazy(() => import(
//     /* webpackChunkName: "pages/AdminPortfolioPage" */
//     'front/pages/adminPortfolio'
// ));

// const AdminPricePage = lazy(() => import(
//     /* webpackChunkName: "pages/AdminPricePage" */
//     'front/pages/adminPrice'
// ));

// const WeddingPage = lazy(() => import(
//     /* webpackChunkName: "pages/WeddingPage" */
//     'front/pages/wedding'
// ));

// const ImagePage = lazy(() => import(
//     /* webpackChunkName: "pages/ImagePage" */
//     'front/pages/image'
// ));

// const BrowsPage = lazy(() => import(
//     /* webpackChunkName: "pages/BrowsPage" */
//     'front/pages/brows'
// ));

// const TrainingPage = lazy(() => import(
//     /* webpackChunkName: "pages/TrainingPage" */
//     'front/pages/training'
// ));

// const AboutPage = lazy(() => import(
//     /* webpackChunkName: "pages/AboutPage" */
//     'front/pages/about'
// ));

// const AirbrushPage = lazy(() => import(
//     /* webpackChunkName: "pages/AirbrushPage" */
//     'front/pages/airbrush'
// ));

// const ContactsPage = lazy(() => import(
//     /* webpackChunkName: "pages/ContactsPage" */
//     'front/pages/contacts'
// ));

// const FeedbackPage = lazy(() => import(
//     /* webpackChunkName: "pages/FeedbackPage" */
//     'front/pages/feedback'
// ));

// const MemoPage = lazy(() => import(
//     /* webpackChunkName: "pages/MemoPage" */
//     'front/pages/memo'
// ));

export function Router() {
    const {pathname} = useLocation()
    const showSections = useShowSections()

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0)
    }, [pathname])

    return (
        // <Suspense fallback={<Loading />}>
        <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/booking' element={<BookingPage />} />
            <Route path='/portfolio' element={<PortfolioPage />} />
            {showSections.brows && <Route path='/booking/brows' element={<BookingBrowsPage />} />}
            <Route path='/booking/image' element={<BookingImagePage />} />
            <Route path='/booking/training' element={<BookingTrainingPage />} />
            <Route path='/admin' element={<AdminPage />} />
            <Route path='/admin/feedback' element={<AdminFeedbackPage />} />
            <Route path='/admin/image' element={<AdminImagePage />} />
            <Route path='/admin/training' element={<AdminTrainingPage />} />
            <Route path='/admin/portfolio' element={<AdminPortfolioPage />} />
            <Route path='/admin/price' element={<AdminPricePage />} />

            <Route path='/wedding' element={<WeddingPage />} />
            <Route path='/image' element={<ImagePage />} />
            {showSections.brows && <Route path='/brows' element={<BrowsPage />} />}
            <Route path='/training' element={<TrainingPage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/contacts' element={<ContactsPage />} />
            <Route path='/airbrush' element={<AirbrushPage />} />
            <Route path='/feedback' element={<FeedbackPage />} />
            <Route path='/memo' element={<MemoPage />} />
        </Routes>
        // </Suspense>
    )
}

// function Loading(): React.ReactElement {
//     const {actions} = useSlice(GlobalContext)
//     const {setIsLoading} = actions

//     useEffect(() => {
//         setIsLoading(true)

//         return () => {
//             setIsLoading(false)
//         }
//     }, [])

//     return null
// }
