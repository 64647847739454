import React from 'react'
import {useNavigate} from 'react-router-dom'
import Stack from '@mui/material/Stack'

import {IBooking} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {GlobalContext} from 'front/globalSlice'
import {useSlice} from 'front/sliceHook'
import {useAddBooking} from 'front/queries/bookings'
import {useSetPhone, useUser} from 'front/queries/user'
import {BookingTrainingForm} from 'front/components/bookingTrainingForm/BookingTrainingForm'
import {Header} from 'front/components/header/Header'

import styles from './bookingTrainingPage.css'

export function BookingTrainingPage() {
    const navigate = useNavigate()
    const {actions} = useSlice(GlobalContext)
    const {mutate: addBooking} = useAddBooking()
    const {mutate: setUserPhone} = useSetPhone()
    const {data: user} = useUser()

    return (
        <Stack className={styles.bookingTrainingPage} spacing={2}>
            <Header text='Запись на обучение' />
            <BookingTrainingForm
                onSubmit={(booking: IBooking) => {
                    sendEvent('trainingSubmitBtn')
                    addBooking(booking, {
                        onSuccess: () => {
                            if (user?.phone !== booking.phone) {
                                setUserPhone(booking.phone)
                            }

                            actions.showAlert({text: 'Совсем скоро мы свяжемся с Вами для уточнения деталей'})

                            setTimeout(() => {
                                actions.setAlertVisibility(false)
                            }, 3000)

                            navigate('/')
                        },
                    })
                }}
            />
        </Stack>
    )
}
