import React from 'react'
import {useNavigate} from 'react-router-dom'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import {sendEvent} from 'front/frontUtils'
import {useIsAdmin} from 'front/queries/user'
import {useShowSections} from 'front/hooks/useCountry'
import {Header} from 'front/components/header/Header'

import styles from './bookingPage.css'

export function BookingPage() {
    const navigate = useNavigate()
    const isAdmin = useIsAdmin()
    const showSections = useShowSections()

    return (
        <Stack className={styles.bookingPage} spacing={2}>
            <Header text={'Запись'} href='/' />
            <div className={styles.header}>На образ / прическу / макияж</div>
            <div className={styles.desc}>
                Здесь вы можете оставить заявку на свадебный или вечерний образ, и я свяжусь с вами для обсуждения
                образа и бронирования даты и времени.
            </div>
            <Button
                color='primary'
                variant='outlined'
                className={styles.btn}
                onClick={() => {
                    sendEvent('bookingImageBtn')
                    navigate('/booking/image')
                }}
            >
                Записаться
            </Button>
            {showSections.brows && (
                <>
                    <div className={styles.header}>На брови</div>
                    <div className={styles.desc}>
                        Здесь вы можете записаться на процедуру коррекции и окрашивания или долговременной укладки
                        бровей.
                    </div>
                    <Button
                        color='primary'
                        variant='outlined'
                        className={styles.btn}
                        onClick={() => {
                            sendEvent('bookingBrowsBtn')
                            navigate('/booking/brows')
                        }}
                    >
                        {isAdmin ? 'Редактировать' : 'Записаться'}
                    </Button>
                </>
            )}
            <div className={styles.header}>На обучение</div>
            <div className={styles.desc}>
                {'Здесь вы можете записаться на авторский обучающий курс «Макияж для себя».'}
            </div>
            <Button
                color='primary'
                variant='outlined'
                className={styles.btn}
                onClick={() => {
                    sendEvent('bookingTrainingBtn')
                    navigate('/booking/training')
                }}
            >
                Записаться
            </Button>
        </Stack>
    )
}
