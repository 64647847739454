import React, {useState} from 'react'
import moment from 'moment'
import Stack from '@mui/material/Stack'

import {ECountry, ERegions, IBooking, IDateInterval, IDateIntervals, IInterval} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {GlobalContext} from 'front/globalSlice'
import {useSlice} from 'front/sliceHook'
import {useAddBooking} from 'front/queries/bookings'
import {useAddHours} from 'front/queries/hours'
import {useSetPhone, useUser} from 'front/queries/user'
import {useCountry} from 'front/hooks/useCountry'

import {BookingForm} from './components/bookingForm/BookingForm'
import {Days} from './components/days/Days'
import {EditHoursForm} from './components/editHoursForm/EditHoursForm'
import styles from './bookingBrowsPage.css'

export function BookingBrowsPage() {
    const [currentDay, setCurrentDay] = useState<IDateIntervals>(null)
    const [currentBook, setCurrentBook] = useState<IDateInterval>(null)
    const {actions} = useSlice(GlobalContext)
    const country = useCountry()

    const {mutate: addHours} = useAddHours()
    const {mutate: addBooking} = useAddBooking()
    const {mutate: setPhone} = useSetPhone()
    const {data: user} = useUser()

    const [region, setRegion] = useState<ERegions>(country === ECountry.CY ? ERegions.pafos : undefined)

    return (
        <Stack className={styles.bookingBrowsPage} spacing={2}>
            {!currentDay && !currentBook && (
                <Days
                    region={region}
                    setRegion={region => {
                        sendEvent('changeBrowsRegion')
                        setRegion(region)
                    }}
                    onSetDay={(date, intervals, region) => {
                        setCurrentDay({
                            date,
                            intervals,
                            region,
                        })
                    }}
                    onIntervalClick={(date, interval, region) => {
                        sendEvent('browsIntervalBtn')
                        setCurrentBook({date, interval, region})
                    }}
                />
            )}
            {currentDay && (
                <EditHoursForm
                    intervals={currentDay.intervals}
                    date={currentDay.date}
                    region={currentDay.region}
                    onSubmit={(intervals: IInterval[]) => {
                        addHours({
                            date: currentDay.date,
                            region: currentDay.region,
                            intervals,
                        })

                        setCurrentDay(null)
                    }}
                    onCancel={() => {
                        setCurrentDay(null)
                    }}
                />
            )}
            {currentBook && (
                <BookingForm
                    date={currentBook.date}
                    region={currentBook.region}
                    interval={currentBook.interval}
                    onSubmit={(booking: IBooking) => {
                        sendEvent('browsSubmitBtn')
                        addBooking(booking, {
                            onSuccess: () => {
                                if (user?.phone !== booking.phone) {
                                    setPhone(booking.phone)
                                }

                                setCurrentBook(null)

                                const mDate = moment(booking.date * 1000)

                                actions.showAlert({
                                    text: `Вы успешно записаны на ${mDate.format('DD MMM')} ${booking.interval.from}`,
                                })
                                setTimeout(() => {
                                    actions.setAlertVisibility(false)
                                }, 3000)
                            },
                        })
                    }}
                    onCancel={() => {
                        setCurrentBook(null)
                    }}
                />
            )}
        </Stack>
    )
}
