import React from 'react'
import {useNavigate} from 'react-router-dom'
import cn from 'clsx'
import LocationOn from '@mui/icons-material/LocationOn'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import {ECountry} from 'shared/consts'
import {getPhoneString} from 'shared/utils'
import {sendEvent} from 'front/frontUtils'
import {useCountry} from 'front/hooks/useCountry'

import styles from './contacts.css'

const phones = {
    [ECountry.CY]: '35795129401',
    [ECountry.RU]: '79262559847',
}

const telegrams = {
    [ECountry.CY]: 'tina_orlova_cyprus',
    [ECountry.RU]: 'tina_orlova',
}

export function Contacts() {
    const navigate = useNavigate()
    const country = useCountry()

    return (
        <Stack spacing={2}>
            <div className={styles.contactsWrap} id='contacts'>
                <a className={styles.phone} href={`tel:+${phones[country]}`} itemProp='telephone'>
                    {getPhoneString(phones[country])}
                </a>
                <div className={styles.socials}>
                    <a
                        className={cn(styles.socLogo, styles.vk)}
                        target='_blank'
                        itemProp='sameAs'
                        href='https://vk.com/tina_orlova'
                    />
                    <a
                        className={cn(styles.socLogo, styles.inst)}
                        target='_blank'
                        itemProp='sameAs'
                        href='https://www.instagram.com/orlova_stylist/'
                    />
                    <a
                        className={cn(styles.socLogo, styles.teleg)}
                        target='_blank'
                        itemProp='sameAs'
                        href={`https://t.me/${telegrams[country]}`}
                    />
                    <a
                        className={cn(styles.socLogo, styles.wa)}
                        target='_blank'
                        itemProp='sameAs'
                        href='https://wa.me/79262559847'
                    />
                </div>
            </div>
            {country === ECountry.RU && (
                <Stack spacing={2} id='address'>
                    <a
                        className={styles.locWrap}
                        target='_blank'
                        href='https://yandex.ru/maps?whatshere%5Bpoint%5D=37.65014527225318%2C55.73768918518213&whatshere%5Bzoom%5D=16'
                    >
                        <LocationOn />
                        <div className={styles.addressWrap}>
                            <span itemProp='address'>Москва, Народная улица, 14с1</span>
                            <div className={styles.metro}>Таганская</div>
                        </div>
                    </a>
                    <a
                        className={styles.locWrap}
                        target='_blank'
                        href='https://yandex.ru/maps?whatshere%5Bpoint%5D=38.768781362955856%2C55.09308142955854&whatshere%5Bzoom%5D=16'
                    >
                        <LocationOn />
                        <span itemProp='address'>Коломна, Советская площадь, 4</span>
                    </a>
                </Stack>
            )}
            <Button
                color='primary'
                variant='outlined'
                onClick={() => {
                    sendEvent('feedbackBtn')
                    navigate('/feedback')
                }}
            >
                Написать нам
            </Button>
        </Stack>
    )
}
