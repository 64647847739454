import moment from 'moment'

export function getHumanTime(time: number) {
    let distance: string

    if (time < 1000) {
        distance = `${time}ms`
    } else if (time < 60 * 1000) {
        distance = `${round(time / 1000, 2)}s`
    } else if (time < 60 * 60 * 1000) {
        distance = `${round(time / (60 * 1000), 2)}min`
    } else {
        distance = `${round(time / (60 * 60 * 1000), 2)}h`
    }

    return distance
}

export function getLogString(pref: string, str: string, timeStart?: number) {
    const res = [`${now()}:`, `[${pref}]`, str]

    if (timeStart) {
        const time = Date.now() - timeStart

        res.push(`(${getHumanTime(time)})`)
    }

    return res.join(' ')
}

export function log(pref: string, str: string, timeStart?: number) {
    console.log(getLogString(pref, str, timeStart))
}

export function now() {
    return `${moment().format('DD.MM.YYYY HH:mm:ss')}`
}

export function round(n: number, precision?: number) {
    const factor = Math.pow(10, precision || 0)

    return Math.round(n * factor) / factor
}

export function sleep(time: number) {
    return new Promise(suceess => {
        setTimeout(suceess, time)
    })
}

export function splitNumberRange(n: string | number, options?: {sep: string}) {
    const nStr = String(n)
    const [int, fract] = nStr.split(/[.,]/)
    let count = 0
    const res = []

    for (const l of int.split('').reverse()) {
        if (count === 3) {
            res.unshift(' ')
            count = 0
        }

        res.unshift(l)
        count++
    }

    if (fract) {
        res.push(options?.sep || '.', fract)
    }

    return res.join('')
}

export function toggleArrayItem<T>(arr: T[], item: T, toggle?: boolean) {
    const index = arr.indexOf(item)
    const res = [...arr]

    if (toggle == null) {
        if (index === -1) {
            res.push(item)
        } else {
            res.splice(index, 1)
        }
    } else {
        if (toggle && index === -1) {
            res.push(item)
        } else if (!toggle && index !== -1) {
            res.splice(index, 1)
        }
    }

    return res
}

export function capitalize(str: string) {
    return str
        .split(' ')
        .map(item => {
            return item[0].toUpperCase() + item.slice(1)
        })
        .join(' ')
}

export function pluralize(count: number, words: string[]) {
    const cases = [2, 0, 1, 1, 1, 2]

    return words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]]
}

export function getPhoneString(p: string) {
    if (p.startsWith('357')) {
        return `+${p.slice(0, 3)}-${p.slice(3, 5)}-${p.slice(5, 8)}-${p.slice(8, 11)}`
    }

    if (p.startsWith('7')) {
        return `+${p.slice(0, 1)} (${p.slice(1, 4)}) ${p.slice(4, 7)}-${p.slice(7, 9)}-${p.slice(9, 11)}`
    }
}

export function getPhoneMask(country: string) {
    switch (country) {
        case 'CY':
            return '+{357}-00-000-000'

        case 'RU':
        default:
            return '+{7} (000) 000-00-00'
    }
}
