import {useSelector} from 'react-redux'

import {ECountry} from 'shared/consts'
import {TRootState} from 'front/store'

export function useCountry() {
    const {country} = useSelector((state: TRootState) => state.init)

    return country
}

export function usePrice(price: number) {
    const country = useCountry()

    if (country === ECountry.CY) {
        return `€\xa0${price}`
    }

    return `${price}\xa0руб.`
}

export function useShowSections() {
    return {
        // brows: useCountry() === ECountry.RU,
        brows: true,
    }
}
