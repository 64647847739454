import React, {useMemo} from 'react'
import Stack from '@mui/material/Stack'

import {ContentSlider} from 'front/components/contentSlider/ContentSlider'

import styles from './comments.css'

const comments = {
    wedding: [
        {
            id: 1,
            name: '',
            comment:
                'Твоя работа – это прям в самое сердце! Я так себе нравлюсь. Все настолько гармонично, подошло под весь образ, под нашу стилистику. И еще ты очень милая и приятная, с тобой было так комфортно и легко.',
        },
        {
            id: 2,
            name: '',
            comment:
                'Все было просто потрясающе! Собрала кучу восхищенных взглядов, все благодаря тебе 🙂 Очень волновалась, что на выездной регистрации с плохой погодой весь образ испортится, но все продержалось безупречно. Рада, что выбрала именно тебя своим свадебным стилистом.',
        },
        {
            id: 3,
            name: '',
            comment:
                'Спасибо за мой свадебный образ, это просто невероятно! Я так боялась, что решила не делать репетицию, но все вышло волшебно. Очень жду фото! Это по-настоящему лучший день в моей жизни и твоя работа просто великолепна. И я не только про сам образ. Я не знаю, как ты это делаешь, но меня ощущение, словно меня лучшая подруга собирала на свадьбу. Благодарю еще раз!',
        },
        {
            id: 4,
            name: '',
            comment:
                'Я так рада, что ты весь свадебный день провела со мной. Что мы сменили образ, что на всех фото у меня просто идеально выглядит и макияж, и прическа. Ты так внимательна была весь день, а с утра нашла такие слова, после которых я перестала так сильно волноваться. Еще мужу очень понравился образ, он боялся, что будет чересчур и что он меня не узнает 😁',
        },
        {
            id: 5,
            name: '',
            comment:
                'Спасибо большое! Красивейший образ! Я собрала столько комплиментов за вечер! Все безусловно понравилось, и макияж, и прическа продержались весь день.',
        },
        {
            id: 6,
            name: '',
            comment:
                'Макияж выдержал слезы, жару, все наши свадебные активности. А мои роскошные локоны выглядят отлично уже третий день. С ними и уехала в свадебное путешествие 😁',
        },
        {
            id: 7,
            name: '',
            comment:
                'Все держалось отлично, я даже не думала, что такое возможно. Плакала на церемонии, а потом просто припудрила, и макияж выглядел, будто его только сделали 🙂 Я очень довольна, ты просто волшебница!',
        },
        {
            id: 8,
            name: '',
            comment:
                'Спасибо за твой профессионализм и что поняла меня с полуслова. Образ получился такой нежный и невесомый, как я и хотела 🙂',
        },
        {
            id: 9,
            name: '',
            comment:
                'Спасибо за ту красоту, что ты сотворила. Все гости были просто в восторге! Теперь мы с мужем любуемся нашими фотографиями. Спасибо, что внесла свой вклад в наш счастливый свадебный день 🙂',
        },
    ],
    image: [
        {
            id: 101,
            name: '',
            comment:
                'Мне безумно понравился образ, я впервые обращалась к стилисту и даже не ожидала такого результата! Боялась, что будет непривычно, так как я сама почти никогда не крашусь. Но все настолько естественно и аккуратно, что теперь мне хочется научиться делать себе самой такой макияж.',
        },
        {
            id: 102,
            name: '',
            comment:
                'Просто чудеса, что из моих волос получилась такая красота! Все аккуратно, красиво и со вкусом. Теперь только к тебе за образом.',
        },
        {
            id: 103,
            name: '',
            comment:
                'Мне так нравится! Меня столько людей спросили, кто делал мне прическу и макияж! Я сама еще никогда не видела себя такой женственной и красивой. Ты моя фея 😊',
        },
        {
            id: 104,
            name: '',
            comment:
                'Спасибо за мой вечерний образ, что порекомендовала сделать именно такой макияж! Очень выразительный и сногсшибательно смотрится на всех фото. Чувствовала себя самой красивой 🙂',
        },
        {
            id: 105,
            name: '',
            comment:
                'Я была самой красивой, стильной и элегантной. Эта волна покорила всех. А мейк чудесно дополнил весь образ. Жду еще мероприятий, чтобы попасть к тебе снова!',
        },
        {
            id: 106,
            name: '',
            comment: 'Спасибо 🙂 Все просто супер 😊 Мужу мой макияж понравился очень, а это высшая похвала 😁',
        },
        {
            id: 107,
            name: '',
            comment:
                'Спасибо вам большое! Все просто идеально. Локоны держались даже на следующий день, а макияж пришлось смыть, но он был просто огонь! ',
        },
        {
            id: 108,
            name: '',
            comment: 'Спасибо за образ! Вы – мастер своего дела. Спасибо за образ и общение! Обязательно вернусь еще.',
        },
        {
            id: 109,
            name: '',
            comment:
                'Благодарю за образ, волосы выглядят роскошно, а макияж вообще не хочется смывать! До сих пор любуюсь!',
        },
        {
            id: 110,
            name: '',
            comment:
                'Спасибо тебе огромное! Даже после окончания мероприятия все осталось целым, ничего не рассыпалось. Ты просто волшебница 😊',
        },
    ],
    brows: [
        {
            id: 201,
            name: '',
            comment:
                'Все спрашивают, где я делаю брови, они просто нереально ухоженно выглядят. Я даже перестала делать макияж на работу, просто крашу ресницы и укладываю бровки, как ты посоветовала. Я выгляжу просто вау!',
        },
        {
            id: 202,
            name: '',
            comment:
                'Спасибо! Быстро и актуально!  Мне очень понравилось, как ты сделала, хотя и я очень придирчивая к бровям.',
        },
        {
            id: 203,
            name: '',
            comment:
                'Спасибо за бровки и что предложила сделать долговременную укладку. Оказалось, что это очень удобно. Теперь буду делать только ее!',
        },
        {
            id: 204,
            name: '',
            comment: 'Спасибо за процедуру и за рекомендации. Мои бровки теперь просто шикарные 🙂 Совсем другой вид. ',
        },
        {
            id: 205,
            name: '',
            comment: 'Спасибо за бровки и за атмосферу, очень нравится, как ты делаешь. Сама бы я так не смогла 🙂',
        },
        {
            id: 206,
            name: '',
            comment: 'Идеальная форма и цвет так подходит мне, спасибо за чудесные брови!',
        },
        {
            id: 207,
            name: '',
            comment:
                'Спасибо! Я еще привыкаю, но они великолепны! Мои брови никогда не были такими аккуратными и послушными.',
        },
    ],
}

export function Comments(props: IProps) {
    const commentsEls = useMemo(() => {
        let items

        if (props.type === 'main') {
            items = [...comments.wedding, ...comments.image]
        } else {
            items = comments[props.type]
        }

        return items.map(item => {
            return (
                <Stack key={item.id} className={styles.commentWrap} spacing={1}>
                    {item.name && <div className={styles.commentHeader}>{item.name}</div>}
                    {/* <div className={styles.commentText}>«{item.comment}»</div> */}
                    <div className={styles.commentText}>{item.comment}</div>
                </Stack>
            )
        })
    }, [comments])

    return <ContentSlider event={props.event}>{commentsEls}</ContentSlider>
}

interface IProps {
    type: 'main' | 'wedding' | 'image' | 'brows'
    event?: string
}
