import React from 'react'
import {useNavigate} from 'react-router-dom'
import image from 'assets/images/pages/airbrush/airbrush1.jpg'
import airbrush from 'assets/images/pictogram/airbrush/airbrush.png'
import feather from 'assets/images/pictogram/airbrush/feather.png'
import filter from 'assets/images/pictogram/airbrush/filter.png'
import selfie from 'assets/images/pictogram/airbrush/selfie.png'
import sos from 'assets/images/pictogram/airbrush/sos.png'
import resistant from 'assets/images/pictogram/airbrush/wristwatch.png'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import {sendEvent} from 'front/frontUtils'
import {Header} from 'front/components/header/Header'
import {Image} from 'front/components/image/Image'

import styles from './airbrushPage.css'

export function AirbrushPage() {
    const navigate = useNavigate()

    return (
        <Stack className={styles.airbrushPage} spacing={1}>
            <Header text={'Аэромакияж'} subText={'Airbrush makeup'} href='/' />
            <Stack spacing={4}>
                <Stack spacing={2}>
                    <Image src={image} width={1024} height={1024} />
                    <Stack className={styles.text} spacing={1}>
                        <div>
                            Это инновационный макияж, который выполняется аэрографом в технике Airbrush путем вуального
                            распыления косметики с помощью Temptu Pro Airpod Innovation.
                        </div>
                        <div>
                            Сверхтонкое естественное покрытие специально разработанных тональных средств позволяет
                            перекрывать даже самые сложные несовершенства.
                        </div>
                        <div>
                            Наносится так легко, что вы не ощущаете на себе тональное средство!
                            <div>С ним комфортно в течение всего дня.</div>
                        </div>
                        {/* <Image src={imageRogov1} width={1024} height={778} /> */}
                    </Stack>
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('aboutBookBtn1')
                            navigate('/booking')
                        }}
                    >
                        Записаться
                    </Button>
                </Stack>
                <Stack spacing={3}>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Безупречный полуматовый финиш, эффект идеальной кожи</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={filter} />
                            <span>
                                Мгновенно делает кожу совершенной за счет наслаивания продукта от легкой до плотной
                                текстуры
                            </span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Стойкость от 12 до 24 часов</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={resistant} />
                            <span>Выдержит дождь, слезы и даже подводную съемку!</span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Ваши портреты без ретуши</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={selfie} />
                            <span>Эффект фотошопа, без маски на лице. Даже при крупных планах</span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Бесконтактное нанесение</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={airbrush} />
                            <span>
                                Самое гигиеничное нанесение тона. Отлично подходит для самой чувствительной кожи
                            </span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Помощь в самых сложных случаях</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={sos} />
                            <span>
                                Легко работает на сложных участках, перекрывает пигментные пятна, красноту, акне, шрамы,
                                не подчеркивает шелушения и другие нюансы кожи
                            </span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Легкое и приятное нанесение тона</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={feather} />
                            <span>
                                Создает легкий ветерок и вуально наносит продукт, дарит комфортные и приятные ощущения
                            </span>
                        </div>
                    </Stack>
                </Stack>
                <Stack spacing={2}>
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('aboutBookBtn2')
                            navigate('/booking')
                        }}
                    >
                        Записаться
                    </Button>
                    <Divider />
                </Stack>
            </Stack>
        </Stack>
    )
}
