import {useMutation, useQuery, useQueryClient} from 'react-query'
import axios from 'axios'

import {IFeedback} from 'shared/consts'

export const useFeedbacks = () => {
    return useQuery<IFeedback[]>(['useFeedbacks'], async () => {
        return await getFeedbacks()
    })
}

export const useAddFeedback = () => {
    const queryClient = useQueryClient()

    return useMutation<unknown, unknown, IFeedback>(
        async feedback => {
            return await addFeedback(feedback)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useFeedbacks')
            },
        },
    )
}

export const useDeleteFeedback = () => {
    const queryClient = useQueryClient()
    const {data: feedbacks} = useFeedbacks()

    return useMutation<unknown, unknown, number>(
        async id => {
            await deleteFeedback(id)
        },
        {
            onSuccess: (res, id) => {
                queryClient.setQueryData(
                    ['useFeedbacks'],
                    feedbacks.filter(item => item.id !== id),
                )
            },
        },
    )
}

const getFeedbacks = async (): Promise<IFeedback[]> => {
    const {data} = await axios({
        method: 'get',
        url: '/api/v1/feedback',
    })

    return data
}

const addFeedback = async (feedback: IFeedback): Promise<void> => {
    const {data} = await axios({
        method: 'post',
        url: '/api/v1/feedback',
        data: feedback,
    })

    return data
}

const deleteFeedback = async (id: number) => {
    await axios({
        method: 'delete',
        url: `/api/v1/feedback/${id}`,
    })
}
