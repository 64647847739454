import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import {ECountry} from 'shared/consts'

const initialState: State = {
    country: ECountry.RU,
}

const initSlice = createSlice({
    name: 'init',
    initialState,
    reducers: {
        setCountry: (state, action: PayloadAction<State['country']>) => {
            state.country = action.payload
        },
    },
})

export const {setCountry} = initSlice.actions

export default initSlice.reducer

export interface State {
    country: ECountry
}
