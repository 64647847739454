import 'front/styles/index.css'

import React from 'react'
import {hydrateRoot} from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {CacheProvider} from '@emotion/react'
import moment from 'moment'
import CssBaseline from '@mui/material/CssBaseline'
import {ThemeProvider} from '@mui/material/styles'

import {createEmotionCache, theme} from 'shared/mui'
import styles from 'front/styles/global.css'

import {App} from './app'
import {getStoreData} from './store'

if (!location.search.includes('canSelect')) {
    const noselect = (e: Event) => {
        const nodeName = (e.target as Element).nodeName.toLowerCase()

        return ['input', 'textarea'].includes(nodeName)
    }

    document.ondragstart = noselect
    document.onselectstart = noselect
    document.oncontextmenu = noselect
    document.body.classList.add(styles.noSelect)
}

const {store} = getStoreData(globalThis.__PRELOADED_STATE__)

delete globalThis.__PRELOADED_STATE__

const cache = createEmotionCache()
window.store = store
window.moment = moment
moment.locale('ru')

const rootEl = document.getElementById('root')

const Root = () => (
    <BrowserRouter>
        <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App store={store} />
            </ThemeProvider>
        </CacheProvider>
    </BrowserRouter>
)

// if (process.env.NODE_ENV === 'development') {
//     createRoot(rootEl).render(<Root />)
// } else {
//     hydrateRoot(rootEl, <Root />)
// }

hydrateRoot(rootEl, <Root />)
