import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'

import {ECountry, ESettings} from 'shared/consts'
import {useSetSettings, useSettings} from 'front/queries/settings'
import {useIsAdmin} from 'front/queries/user'
import {useCountry} from 'front/hooks/useCountry'
import {Header} from 'front/components/header/Header'

import styles from './adminPricePage.css'

export function AdminPricePage() {
    const isAdmin = useIsAdmin()
    const {data: settings} = useSettings()
    const [priceLists, setPriceLists] = useState<Record<ECountry, string>>()
    // const [priceList, setPriceList] = useState<IPriceListItem>()
    const {mutate: saveSettings} = useSetSettings()
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const country = useCountry()

    const [currentCountry, setCurrentCountry] = useState<ECountry>(country)

    useEffect(() => {
        if (settings?.priceList) {
            setPriceLists({
                [ECountry.CY]: JSON.stringify(settings.priceList.CY, null, 2),
                [ECountry.RU]: JSON.stringify(settings.priceList.RU, null, 2),
            })
        }
    }, [settings])

    if (!isAdmin || !priceLists) {
        return null
    }

    return (
        <Stack className={styles.adminPricePage} spacing={2}>
            <Header text={'Прайс-лист'} href='/admin' />
            <FormControl size='small' sx={{width: '150px'}}>
                <InputLabel id='actual-select-label'>Дата</InputLabel>
                <Select
                    value={currentCountry}
                    label='Страна'
                    onChange={e => {
                        setCurrentCountry(e.target.value as ECountry)
                    }}
                >
                    <MenuItem value={ECountry.RU}>Россия</MenuItem>
                    <MenuItem value={ECountry.CY}>Кипр</MenuItem>
                </Select>
            </FormControl>
            <textarea
                className={styles.text}
                // rows={1}
                // cols={1}
                value={priceLists[currentCountry]}
                onChange={e => {
                    setError(null)
                    setSuccess(null)
                    setPriceLists({
                        ...priceLists,
                        [currentCountry]: e.target.value,
                    })
                }}
            />
            <Button
                className={styles.button}
                color='primary'
                variant='contained'
                onClick={async () => {
                    let res

                    try {
                        res = {
                            [ECountry.CY]: JSON.parse(priceLists.CY),
                            [ECountry.RU]: JSON.parse(priceLists.RU),
                        }
                    } catch (e) {
                        setError('Невалидный JSON')
                        return
                    }

                    saveSettings(
                        {[ESettings.priceList]: res},
                        {
                            onSuccess: () => {
                                setSuccess('Изменения сохранены')
                                // navigate('/');
                            },
                        },
                    )
                }}
            >
                Сохранить
            </Button>
            <div className={styles.footer}>
                {error && <div className={styles.error}>{error}</div>}
                {success && <div className={styles.success}>{success}</div>}
            </div>
        </Stack>
    )
}
