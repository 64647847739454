import React, {useEffect, useState} from 'react'
import {DndContext, MeasuringStrategy, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core'
import {
    arrayMove,
    defaultAnimateLayoutChanges,
    rectSortingStrategy,
    SortableContext,
    useSortable,
} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import cn from 'clsx'
import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import DragIndicator from '@mui/icons-material/DragIndicator'
import Save from '@mui/icons-material/Save'
import Share from '@mui/icons-material/Share'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import {useAddImages, useDeleteImage, useDeletePortfolio, useUpdatePortfolio} from 'front/queries/portfolio'

import styles from './portfolioItem.css'

function SortableItem(props: ISortableItemProps) {
    const {imageId, portfolioId, setClickId, clickId} = props
    const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
        id: imageId,
        animateLayoutChanges: args => {
            return defaultAnimateLayoutChanges({...args, wasDragging: true})
        },
    })

    const {mutate: deleteImage} = useDeleteImage()

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
    }

    const showButtons = clickId === imageId

    return (
        <div
            className={styles.imageWrap}
            ref={setNodeRef}
            style={style}
            onClick={() => {
                if (clickId === imageId) {
                    setClickId(null)
                } else {
                    setClickId(imageId)
                }
            }}
            {...attributes}
        >
            <img
                className={cn(styles.image, isDragging && styles.draggingImage)}
                src={`/api/v1/images/${imageId}?small=1`}
            />
            <div className={cn(styles.buttonsWrap, !showButtons && styles.hidden)}>
                <div
                    className={styles.deleteImage}
                    onClick={e => {
                        e.stopPropagation()

                        deleteImage({id: imageId, portfolioId})
                    }}
                >
                    <Delete />
                </div>
                <div
                    className={styles.shareImage}
                    onClick={e => {
                        e.stopPropagation()

                        const {protocol, host} = location

                        navigator.share?.({
                            title: 'Фотография orlova-stylist.ru',
                            // text: 'Orlova Stylist Photo',
                            url: `${protocol}//${host}/api/v1/images/${imageId}`,
                        })

                        navigator.clipboard.writeText(`${protocol}//${host}/api/v1/images/${imageId}`)
                    }}
                >
                    <Share />
                </div>
            </div>
            <div className={styles.dragBtn} {...listeners}>
                <DragIndicator />
            </div>
        </div>
    )
}

export function PortfolioItem(props: IProps) {
    const {id, name, orderId, images} = props

    const [currentName, setCurrentName] = useState(name)
    const [currentOrderId, setCurrentOrderId] = useState(orderId)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)

    const {mutate: updatePortfolio} = useUpdatePortfolio()
    const {mutate: addImages} = useAddImages()
    const {mutate: deletePortfolio} = useDeletePortfolio()

    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

    const [activeId, setActiveId] = useState<number>(null)
    const [clickId, setClickId] = useState<number>(null)
    const [items, setItems] = useState<number[]>([])

    useEffect(() => {
        setItems(images)
    }, [images])

    return (
        <Stack spacing={1} className={styles.portfolioItem}>
            <Stack spacing={1} direction='row'>
                <TextField
                    label='Имя'
                    sx={{width: '160px'}}
                    variant='outlined'
                    size='small'
                    value={currentName}
                    onChange={e => {
                        setCurrentName(e.target.value)
                        setIsChanged(true)
                    }}
                />
                <TextField
                    label='№'
                    sx={{width: '50px'}}
                    variant='outlined'
                    size='small'
                    value={currentOrderId ?? ''}
                    onChange={e => {
                        const value = e.target.value.trim()
                        let orderId = null

                        if (value) {
                            orderId = Number(value)

                            if (isNaN(orderId)) {
                                orderId = null
                            }
                        }

                        setCurrentOrderId(orderId)

                        setIsChanged(true)
                    }}
                />
                <Button
                    className={styles.button}
                    variant='contained'
                    disabled={!isChanged || !currentName || !currentOrderId}
                    onClick={() => {
                        const portfolio = {
                            id,
                            name: currentName,
                            orderId: currentOrderId,
                        }

                        updatePortfolio(portfolio, {
                            onSuccess: () => setIsChanged(false),
                        })
                    }}
                >
                    <Save />
                </Button>
                <Button
                    className={styles.button}
                    variant='contained'
                    onClick={() => {
                        setOpenDeleteDialog(true)
                    }}
                >
                    <Delete />
                </Button>
            </Stack>
            <DndContext
                measuring={{droppable: {strategy: MeasuringStrategy.Always}}}
                sensors={sensors}
                onDragStart={({active}) => {
                    setClickId(null)
                    setActiveId(active.id as number)
                }}
                onDragEnd={({over}) => {
                    const overId = over?.id as number

                    setActiveId(null)

                    if (!overId || overId === activeId) {
                        return
                    }

                    const startIndex = items.findIndex(item => item === activeId)
                    const endIndex = items.findIndex(item => item === overId)
                    const newItems = arrayMove(items, startIndex, endIndex)

                    updatePortfolio(
                        {id, images: newItems},
                        {
                            onError: () => setItems(items),
                        },
                    )

                    setItems(newItems)
                }}
            >
                <div className={styles.images}>
                    <SortableContext items={items} strategy={rectSortingStrategy}>
                        {items.map(imageId => {
                            return (
                                <SortableItem
                                    key={imageId}
                                    imageId={imageId}
                                    portfolioId={id}
                                    setClickId={setClickId}
                                    clickId={clickId}
                                />
                            )
                        })}
                    </SortableContext>
                    <div className={styles.file}>
                        <input
                            disabled={loadingImage}
                            className={styles.inputFile}
                            id={`${id}-portfolio-file-upload`}
                            type='file'
                            multiple
                            onChange={e => {
                                setLoadingImage(true)

                                addImages(
                                    {portfolioId: id, images: e.target.files},
                                    {
                                        onSettled: () => {
                                            setLoadingImage(false)
                                        },
                                    },
                                )
                            }}
                        />
                        <label className={styles.inputLabel} htmlFor={`${id}-portfolio-file-upload`}>
                            {loadingImage ? <CircularProgress /> : <Add />}
                        </label>
                    </div>
                </div>
            </DndContext>
            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false)
                }}
            >
                <DialogTitle>Удалить портфолио "{currentName}"?</DialogTitle>
                <DialogActions>
                    <Button
                        autoFocus
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            setOpenDeleteDialog(false)
                        }}
                    >
                        Нет
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            deletePortfolio(id, {
                                onSuccess: () => {
                                    setOpenDeleteDialog(false)
                                },
                            })
                        }}
                    >
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

interface IProps {
    id: number
    name: string
    images: number[]
    orderId: number
}

interface ISortableItemProps {
    imageId: number
    portfolioId: number
    clickId: number
    setClickId: (id: number) => void
}
