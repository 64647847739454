import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import cn from 'clsx'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'

import {COLORS} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {GlobalContext} from 'front/globalSlice'
import {useSlice} from 'front/sliceHook'
import {useIsAdmin} from 'front/queries/user'
import {useShowSections} from 'front/hooks/useCountry'

import styles from './head.css'

export function Head() {
    const {state, actions} = useSlice(GlobalContext)
    const {isLoading, showMenu} = state
    const {setShowMenu} = actions
    const navigate = useNavigate()

    const isAdmin = useIsAdmin()
    const showSections = useShowSections()

    const handleClose = () => {
        setShowMenu(false)
    }

    const linkStyle = {
        color: COLORS.light,
    }

    const [showAdmin, setShowAdmin] = useState(false)

    return (
        <AppBar
            position='fixed'
            style={{
                backgroundColor: COLORS.light,
                color: COLORS.dark,
                zIndex: 30,
                maxWidth: 'inherit',
                right: 'inherit',
            }}
            onClick={() => {
                setShowMenu(false)
            }}
        >
            <Toolbar sx={{height: '64px'}}>
                <Link className={styles.logo} id='logo_url' itemProp='url' to='/' />
                <Box sx={{flexGrow: 1}} />
                {!showMenu && (
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        onClick={e => {
                            e.stopPropagation()
                            sendEvent('mainMenuBtn')
                            setShowMenu(true)
                        }}
                    >
                        <MenuIcon sx={{fontSize: 28}} />
                    </IconButton>
                )}
                {showMenu && (
                    <IconButton edge='start' aria-label='menu' onClick={handleClose}>
                        <CloseIcon sx={{fontSize: 28}} />
                    </IconButton>
                )}
                <Grow in={showMenu} style={{transformOrigin: 'top'}}>
                    <div
                        style={{
                            backgroundColor: COLORS.dark,
                        }}
                        className={styles.menuList}
                        onClick={e => {
                            e.stopPropagation()
                        }}
                    >
                        {isAdmin && (
                            <MenuItem>
                                <Link onClick={handleClose} className={styles.link} style={linkStyle} to='/admin'>
                                    Админка
                                </Link>
                            </MenuItem>
                        )}
                        <MenuItem>
                            <Link onClick={handleClose} className={styles.link} style={linkStyle} to='/booking'>
                                Записаться
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link onClick={handleClose} className={styles.link} style={linkStyle} to='/wedding'>
                                Свадебный образ
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link onClick={handleClose} className={styles.link} style={linkStyle} to='/image'>
                                Вечерний и дневной образ
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link onClick={handleClose} className={styles.link} style={linkStyle} to='/airbrush'>
                                Аэромакияж (Airbrush makeup)
                            </Link>
                        </MenuItem>
                        {showSections.brows && (
                            <MenuItem>
                                <Link onClick={handleClose} className={styles.link} style={linkStyle} to='/brows'>
                                    Оформление бровей
                                </Link>
                            </MenuItem>
                        )}
                        <MenuItem>
                            <Link onClick={handleClose} className={styles.link} style={linkStyle} to='/training'>
                                Обучение
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link onClick={handleClose} className={styles.link} style={linkStyle} to='/about'>
                                Обо мне
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link onClick={handleClose} className={styles.link} style={linkStyle} to='/contacts'>
                                Контакты
                            </Link>
                        </MenuItem>
                        <div className={styles.socials}>
                            <a
                                onClick={handleClose}
                                className={cn(styles.socLogo, styles.vk)}
                                target='_blank'
                                href='https://vk.com/tina_orlova'
                            />
                            <a
                                onClick={handleClose}
                                className={cn(styles.socLogo, styles.inst)}
                                target='_blank'
                                href='https://www.instagram.com/orlova_stylist/'
                            />
                            <a
                                onClick={handleClose}
                                className={cn(styles.socLogo, styles.teleg)}
                                target='_blank'
                                href='https://t.me/tina_orlova'
                            />
                        </div>
                        <div
                            onClick={() => {
                                if (showAdmin) {
                                    setShowAdmin(false)
                                    setShowMenu(false)
                                    navigate('/admin')
                                } else {
                                    setShowAdmin(true)

                                    setTimeout(() => {
                                        setShowAdmin(false)
                                    }, 300)
                                }
                            }}
                            className={styles.admin}
                        />
                    </div>
                </Grow>
            </Toolbar>
            {isLoading && <LinearProgress sx={{width: '100%', height: '1px'}} />}
        </AppBar>
    )
}
