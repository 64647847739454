import createCache from '@emotion/cache'
import {createTheme} from '@mui/material/styles'

import {COLORS} from './consts'

export const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeightMedium: 500,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    boxShadow: 'none',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    boxShadow:
                        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                },
                message: {
                    fontSize: '13px',
                    lineHeight: '20px',
                },
            },
        },
    },
    palette: {
        primary: {
            main: COLORS.dark,
            dark: COLORS.dark,
            contrastText: COLORS.light,
        },
        secondary: {
            main: COLORS.light,
            dark: COLORS.lightHover,
            contrastText: COLORS.dark,
        },
        success: {
            main: COLORS.colorBrown,
        },
    },
})

export function createEmotionCache() {
    return createCache({key: 'css', prepend: true})
}
