import React, {useMemo, useRef, useState} from 'react'
import moment from 'moment'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'

import {DATE_FORMAT, ECountry, ERegions, ESettings, IInterval} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {useHours} from 'front/queries/hours'
import {useSettings} from 'front/queries/settings'
import {useIsAdmin, useSetPhone, useUser} from 'front/queries/user'
import {useCountry} from 'front/hooks/useCountry'
import {CitySelect} from 'front/components/citySelect/CitySelect'
import {Header} from 'front/components/header/Header'
import {Phone} from 'front/components/phone/Phone'

import {Day} from '../day/Day'
import styles from './days.css'

export function Days(props: IProps) {
    const {region, setRegion} = props
    const country = useCountry()

    const {data: regionDays} = useHours()
    const {data: settings} = useSettings()
    const {data: user} = useUser()
    const isAdmin = useIsAdmin()
    const {mutate: setUserPhone} = useSetPhone()
    const maxDays = settings?.[ESettings.maxDays]

    const [showPopover, setShowPopover] = useState(false)
    const [phone, setPhone] = useState('')
    const buttonRef = useRef(null)

    const daysEls = useMemo(() => {
        if (!regionDays || !maxDays || !region) {
            return []
        }

        const days = regionDays[region] || {}
        const from = moment().startOf('day').unix()
        const to = moment()
            .startOf('day')
            .add(maxDays - 1, 'day')
            .unix()

        let current = from
        const res = []

        while (current <= to) {
            const date = current
            const mDate = moment(current * 1000)
            const intervals = days[mDate.format(DATE_FORMAT)] || []

            if (isAdmin || intervals.length > 0) {
                if (res.length > 0) {
                    res.push(<Divider key={`devider${date}`} />)
                }

                res.push(
                    <Day
                        key={date}
                        date={date}
                        region={region}
                        intervals={intervals}
                        onEdit={() => {
                            props.onSetDay(date, intervals, region)
                        }}
                        onIntervalClick={interval => {
                            props.onIntervalClick(date, interval, region)
                        }}
                    />,
                )
            }

            current = mDate.add(1, 'day').unix()
        }

        if (!res.length) {
            return <div className={styles.disclaimer}>Нет свободных мест</div>
        }

        return res
    }, [regionDays, maxDays, region])

    return (
        <Stack className={styles.days} spacing={2}>
            <Header text={'Запись на брови'} />
            <Stack spacing={3}>
                <CitySelect
                    city={region}
                    setCity={setRegion}
                    cities={country === ECountry.CY ? [ERegions.pafos] : null}
                />
                <Stack spacing={2}>{daysEls}</Stack>
            </Stack>
            {!user?.phone && !isAdmin && region && (
                <Button
                    ref={buttonRef}
                    className={styles.checkBtn}
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                        sendEvent('alreadyBookBtn')
                        setShowPopover(!showPopover)
                    }}
                >
                    Уже записаны?
                </Button>
            )}
            <Popover
                PaperProps={{
                    className: styles.phonePopup,
                }}
                className={styles.phonePopup}
                open={showPopover}
                anchorEl={buttonRef.current}
                disableRestoreFocus
                onClose={() => {
                    setShowPopover(false)
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Stack spacing={2}>
                    <Phone setPhone={setPhone} phone={phone} autoFocus />
                    <Button
                        disabled={phone.length < 11}
                        className={styles.checkBtn}
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            setShowPopover(false)
                            setUserPhone(phone)
                        }}
                    >
                        Проверить
                    </Button>
                </Stack>
            </Popover>
        </Stack>
    )
}

interface IProps {
    region: ERegions
    setRegion: (region: ERegions) => void
    onSetDay: (date: number, intervals: IInterval[], region: ERegions) => void
    onIntervalClick?: (date: number, interval: IInterval, region: ERegions) => void
}
