import React from 'react'
import {PayloadAction} from '@reduxjs/toolkit'
import {AlertColor} from '@mui/material/Alert'

import {createUseSliceHook} from 'front/sliceHook'

const initialState: ISliceState = {
    alert: {
        show: false,
        type: 'success',
        text: '',
    },
    isLoading: false,
    showMenu: false,
}

const reducers = {
    setAlertVisibility(state: ISliceState, action: PayloadAction<boolean>) {
        state.alert.show = action.payload
    },
    showAlert(state: ISliceState, action: PayloadAction<{text: string; type?: AlertColor}>) {
        state.alert = {
            text: action.payload.text,
            type: action.payload.type || 'success',
            show: true,
        }
    },
    setIsLoading(state: ISliceState, action: PayloadAction<boolean>) {
        state.isLoading = action.payload
    },
    setShowMenu(state: ISliceState, action: PayloadAction<boolean>) {
        state.showMenu = action.payload
    },
}

export const useGlobalSlice = createUseSliceHook({
    name: 'global',
    initialState,
    reducers,
})

export const GlobalContext = React.createContext<TSlice>(null)

interface ISliceState {
    alert: {
        show: boolean
        type: AlertColor
        text: string
    }
    isLoading: boolean
    showMenu: boolean
}

type TSlice = ReturnType<typeof useGlobalSlice>
