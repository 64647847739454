import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import {sendEvent} from 'front/frontUtils'
import {GlobalContext} from 'front/globalSlice'
import {useSlice} from 'front/sliceHook'
import {useAddFeedback} from 'front/queries/feedback'
import {Header} from 'front/components/header/Header'
import {Phone} from 'front/components/phone/Phone'

import styles from './feedbackPage.css'

export function FeedbackPage() {
    const {actions} = useSlice(GlobalContext)

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [comment, setComment] = useState('')

    const navigate = useNavigate()
    const {mutate: addFeedback} = useAddFeedback()

    return (
        <Stack className={styles.feedbackPage} spacing={2}>
            <Header text={'Обратная связь'} href='/' />
            <div className={styles.head}>
                Если у вас есть замечания, комментарии или предложения, мы будем рады их выслушать.
            </div>
            <TextField
                required
                label='Имя'
                variant='outlined'
                size='small'
                inputProps={{
                    maxLength: 50,
                }}
                value={name}
                onChange={e => {
                    setName(e.target.value)
                }}
            />
            <Phone setPhone={setPhone} phone={phone} />
            <TextField
                required
                label='Сообщение'
                variant='outlined'
                size='small'
                inputProps={{
                    maxLength: 1000,
                }}
                multiline
                minRows={3}
                maxRows={5}
                value={comment}
                onChange={e => {
                    setComment(e.target.value)
                }}
            />
            <Button
                disabled={!name.trim() || phone.length !== 11 || !comment}
                color='primary'
                fullWidth
                variant='contained'
                onClick={async () => {
                    sendEvent('feedbackSubmitBtn')
                    addFeedback(
                        {
                            name: name.trim(),
                            phone,
                            comment: comment.trim(),
                        },
                        {
                            onSuccess: () => {
                                actions.showAlert({text: 'Спасибо за обращение'})

                                setTimeout(() => {
                                    actions.setAlertVisibility(false)
                                }, 3000)

                                navigate('/')
                            },
                        },
                    )
                }}
            >
                Отправить
            </Button>
        </Stack>
    )
}
