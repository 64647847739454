import React from 'react'

import styles from './memo.css'

export function Memo() {
    return (
        <div className={styles.memo}>
            <div className={styles.header}>Волосы</div>
            <div>От подготовки зависит стойкость и внешний вид будущей укладки. Волосы должны быть чистые и сухие.</div>
            <div>
                {
                    'Мыть голову можно и с вечера, но если волосы быстро пачкаются - лучше утром, за 1 - 2 часа до укладки.'
                }
            </div>
            <div>
                Высушивать <span className={styles.strong}>обязательно</span> феном, желательно с расческой, приподнимая
                корни, или головой вниз для создания объема.
            </div>
            <div>
                Высушивать <span className={styles.strong}>обязательно</span> до состояния абсолютно сухих. Если у вас
                очень густые волосы и вы знаете, что они долго сохнут, лучше подготовить их с вечера по инструкции выше.
            </div>
            <div className={styles.header}>Лицо</div>
            <div>
                Если вы заранее планируете визит к стилисту, вы можете поухаживать за кожей увлажняющими средствами:
                подойдут увлажняющие маски, крема для лица, ухаживающие бальзамы для губ.
            </div>
            <div>Не используйте новые для вас средства, чтобы не допустить нежелательных реакций кожи.</div>
            <div>
                Поход к косметологу, солярий, баня, пилинги в домашних условиях - не позднее, чем за неделю до события.
            </div>
            <div>
                Окрашивание бровей, эпиляция волос на лице, наращивание ресниц - не позднее, чем за 3 дня до события.
            </div>
            <div className={styles.header}>Общие рекомендации</div>
            <ul className={styles.list}>
                <li>
                    Наденьте одежду, которая снимается не через голову, чтобы не задеть прическу. Желательно не надевать
                    бюстгальтер с лямками, так как от них остаются неприятные следы на коже, которые проходят не так
                    быстро, как хотелось бы
                </li>
                <li>
                    Если планируется открытое платье, обговорите это со стилистом, чтобы мастер мог подготовить зону
                    декольте
                </li>
                <li>
                    Если сборы происходят с выездом, позаботьтесь о светлом рабочем месте, зеркале, розетках и, по
                    возможности, высоком стуле
                </li>
                <li>
                    Расходники (невидимки, шпильки, укладочные средства, косметика) включены в стоимость образа.
                    Исключения – это украшения в волосы, которыми мы можем украсить вашу укладку, прическу. Также можете
                    взять любимые средства из вашей косметички, если вы хотите, чтобы на каком-то этапе мы использовали
                    именно ваш продукт
                </li>
            </ul>
        </div>
    )
}
