import React, {useCallback, useEffect, useRef, useState} from 'react'
import dispImage from 'assets/images/displ.jpg'
import cn from 'clsx'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'

import {sendEvent} from 'front/frontUtils'
import {GlobalContext} from 'front/globalSlice'
import {useSlice} from 'front/sliceHook'

import {useShowAndHide} from './hooks'
import {ImageSlider} from './imagesSlider'
import styles from './slider.css'

const minSwipeDistance = 30

export function Slider(props: IProps) {
    const {images, event} = props

    const {actions} = useSlice(GlobalContext)
    const {setIsLoading} = actions

    const [currentImage, setCurrentImage] = useState(0)
    const [slider, setSlider] = useState(null)
    const [x, setX] = useState<number>(null)
    const [y, setY] = useState<number>(null)
    const imageEl = useRef(null)

    const {isHidden, setVisible} = useShowAndHide()

    const sendEventCb = useCallback(() => {
        if (event) {
            sendEvent(event)
        }
    }, [event, sendEvent])

    const onPrev = useCallback(() => {
        slider.prev()
        setVisible()
    }, [slider, setVisible])

    const onNext = useCallback(() => {
        slider.next()
        setVisible()
    }, [slider, setVisible])

    useEffect(() => {
        setIsLoading(true)

        const imageSlider = new ImageSlider({
            parent: imageEl.current,
            dispImage,
            images,
            onChange: index => {
                sendEventCb()
                setCurrentImage(index)
            },
        })

        imageSlider.init().then(() => {
            if (imageSlider.cleared) {
                return
            }

            setIsLoading(false)
            setSlider(imageSlider)
        })

        return () => {
            setIsLoading(false)
            imageSlider.clear()
        }
    }, [])

    return (
        <div
            className={styles.sliderWrap}
            onTouchStartCapture={e => {
                if (e.touches.length > 1) {
                    setX(null)
                    setY(null)
                } else {
                    setX(e.changedTouches[0].clientX)
                    setY(e.changedTouches[0].clientY)
                }
            }}
            onTouchMoveCapture={e => {
                if (!x || !y) {
                    return
                }

                const xDiff = x - e.changedTouches[0].clientX
                const yDiff = y - e.changedTouches[0].clientY
                const absXDiff = Math.abs(xDiff)
                const absYDiff = Math.abs(yDiff)

                if (absXDiff > absYDiff) {
                    if (absXDiff > minSwipeDistance) {
                        if (xDiff > 0) {
                            onNext()
                        } else {
                            onPrev()
                        }

                        setX(null)
                        setY(null)
                    }
                }
            }}
            onTouchEndCapture={() => {
                setX(null)
                setY(null)
            }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center' className={styles.slider}>
                {slider && (
                    <div className={styles.buttonLeftWrap} onClick={onPrev}>
                        {currentImage !== 0 && (
                            <Button
                                disabled={currentImage === 0}
                                className={cn(styles.buttonLeft, isHidden && styles.hidden)}
                                color='primary'
                                variant='contained'
                                aria-label='back'
                            >
                                <ArrowBack />
                            </Button>
                        )}
                    </div>
                )}
                <Fade in timeout={500}>
                    <div ref={imageEl} className={styles.image} />
                </Fade>
                {slider && (
                    <div className={styles.buttonRightWrap} onClick={onNext}>
                        {currentImage < images.length - 1 && (
                            <Button
                                disabled={currentImage === images.length - 1}
                                className={cn(styles.buttonRight, isHidden && styles.hidden)}
                                color='primary'
                                variant='contained'
                                aria-label='back'
                            >
                                <ArrowForward />
                            </Button>
                        )}
                    </div>
                )}
            </Stack>
            <div className={styles.dots}>
                {images.map((image, index) => {
                    return <div key={image} className={cn(styles.dot, index === currentImage && styles.activeDot)} />
                })}
            </div>
        </div>
    )
}

interface IProps {
    images: string[]
    event?: string
}
