import React from 'react'
import {useNavigate} from 'react-router-dom'
import certImage from 'assets/images/pages/training/cert.jpg'
import topImage from 'assets/images/pages/training/top.jpg'
import trainingComment from 'assets/video/trainingComment.mp4'
import {Skeleton} from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import {ECountry, ESettings} from 'shared/consts'
import {splitNumberRange} from 'shared/utils'
import {sendEvent} from 'front/frontUtils'
import {useSettings} from 'front/queries/settings'
import {useCountry} from 'front/hooks/useCountry'
import {Header} from 'front/components/header/Header'
import {Image} from 'front/components/image/Image'

import styles from './trainingPage.css'

export function TrainingPage() {
    const navigate = useNavigate()
    const country = useCountry()
    const {data: settings} = useSettings()

    return (
        <Stack className={styles.trainingPage} spacing={2}>
            <Header text={'Обучение'} href='/' />
            <Stack spacing={2}>
                <Image src={topImage} width={1024} height={1102} />
                <div className={styles.header}>{'Авторский обучающий курс «Макияж для себя»'}</div>
                <div className={styles.text}>
                    <div>
                        Разберем ежедневный уход, косметичку. Подберем кисти и косметику на каждый день, для дневного и
                        вечернего макияжа.
                    </div>
                    <div>
                        Изучим технику и особенности нанесения косметики. Научимся делать профессиональный макияж себе
                        самостоятельно.
                    </div>
                    <div>
                        Косметика (профессиональная, бюджетная, люксовая), кисти, расходные материалы предоставляются на
                        время обучения.
                    </div>
                    <div>По окончании курса выдается сертификат:</div>
                    <Image src={certImage} width={1024} height={1318} />
                    <Divider className={styles.hidden} />
                    <div className={styles.label}>Ключевые пункты программы:</div>
                    <ul className={styles.list}>
                        <li>разбор косметички</li>
                        <li>ежедневный уход</li>
                        <li>обзор декоративной косметики</li>
                        <li>моделирование лица, построение форм</li>
                        <li>коррекция глаз, носа, губ с учетом ваших особенностей</li>
                        <li>подбор цветовой гаммы для макияжа</li>
                        <li>определение необходимого набора косметики и кистей на каждый день</li>
                        <li>отработка на себе</li>
                    </ul>
                    <div className={styles.label}>Преимущества:</div>
                    <ul className={styles.list}>
                        <li>обучение - моя суперспособность🙂</li>
                        <li>индивидуально подбираем время занятия</li>
                        <li>программа корректируется в зависимости от запроса</li>
                        <li>
                            обратная связь по отработке макияжа в течение месяца, если после занятия останутся вопросы
                        </li>
                    </ul>
                    <div className={styles.label}>Стоимость:</div>
                    <ul className={styles.list}>
                        <li>
                            <Stack spacing={0.5} direction='row'>
                                <span>теория и практика -</span>
                                {settings ? (
                                    <span>
                                        {country === ECountry.CY
                                            ? '€ 200'
                                            : `${splitNumberRange(settings[ESettings.traningPrice])} ₽`}
                                    </span>
                                ) : (
                                    <Skeleton width={80} />
                                )}
                            </Stack>
                        </li>
                    </ul>
                </div>
                <div className={styles.header}>Видеоотзыв о курсе</div>
                <video
                    className={styles.video}
                    controls
                    preload='yes'
                    playsInline
                    disableRemotePlayback
                    onPlay={() => {
                        sendEvent('trainingCommentVideo')
                    }}
                >
                    <source src={`${trainingComment}#t=0.1`} type='video/mp4' />
                </video>
                <Button
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                        sendEvent('trainingBookBtn1')
                        navigate('/booking/training')
                    }}
                >
                    Записаться
                </Button>
                <Divider />
            </Stack>
        </Stack>
    )
}
