import React from 'react'
import Alert from '@mui/material/Alert'
import Slide from '@mui/material/Slide'

import {GlobalContext, useGlobalSlice} from 'front/globalSlice'
import {Router} from 'front/pages/Router'
import {useSlice} from 'front/sliceHook'

import {Head} from './head/Head'
import styles from './layout.css'

export function LayoutComponent() {
    const slice = useGlobalSlice('global')
    const {state, actions} = useSlice(slice)
    const {alert} = state
    const {setShowMenu} = actions

    return (
        <GlobalContext.Provider value={slice}>
            <div className={styles.main}>
                <div className={styles.content}>
                    <div className={styles.alertWrap}>
                        <Slide
                            direction={alert.show ? 'left' : 'right'}
                            timeout={{
                                enter: 600,
                                exit: 300,
                            }}
                            in={alert.show}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Alert
                                style={{
                                    margin: '0 10px',
                                }}
                                className={styles.alert}
                                color='success'
                                severity={alert.type}
                                onClose={() => {
                                    actions.setAlertVisibility(false)
                                }}
                            >
                                {alert.text}
                            </Alert>
                        </Slide>
                    </div>
                    <Head />
                    <div className={styles.body} onClick={() => setShowMenu(false)}>
                        <Router />
                    </div>
                </div>
            </div>
        </GlobalContext.Provider>
    )
}

export const Layout = LayoutComponent
