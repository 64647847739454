import React from 'react'
import Stack from '@mui/material/Stack'

import {Contacts} from 'front/components/contacts/Contacts'
import {Header} from 'front/components/header/Header'

import styles from './contactsPage.css'

export function ContactsPage() {
    return (
        <Stack className={styles.contactsPage} spacing={1}>
            <Header text={'Контакты'} href='/' />
            <Contacts />
        </Stack>
    )
}
