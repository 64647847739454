import React, {useState} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import {COLORS, EBookingTypes, EMethods, IBooking, METHODS} from 'shared/consts'
import {useDeleteBooking} from 'front/queries/bookings'
import {CitySelect} from 'front/components/citySelect/CitySelect'
import {Phone} from 'front/components/phone/Phone'

import styles from './bookingTrainingForm.css'

export function BookingTrainingForm(props: IProps) {
    const {booking, canDelete} = props
    const [name, setName] = useState(booking?.name || '')
    const [surname, setSurname] = useState(booking?.surname || '')
    const [phone, setPhone] = useState(booking?.phone || '')
    const [comment, setComment] = useState(booking?.comment || '')
    const [region, setRegion] = useState(booking?.region || undefined)
    const [method, setMethod] = useState<EMethods>(booking?.method || EMethods.noMatter)

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    const {mutate: deleteBooking} = useDeleteBooking()

    return (
        <Stack spacing={2}>
            <div className={styles.head}>Заполните форму, и мы свяжемся с Вами для уточнения деталей</div>
            <TextField
                required
                label='Имя'
                variant='outlined'
                size='small'
                inputProps={{
                    maxLength: 50,
                }}
                value={name}
                onChange={e => {
                    setName(e.target.value)
                }}
            />
            <TextField
                label='Фамилия'
                variant='outlined'
                size='small'
                inputProps={{
                    maxLength: 50,
                }}
                value={surname}
                onChange={e => {
                    setSurname(e.target.value)
                }}
            />
            <Phone setPhone={setPhone} phone={phone} />
            <CitySelect required city={region} setCity={setRegion} />
            <FormControl size='small'>
                <InputLabel id='method-select-label'>Способ связи</InputLabel>
                <Select
                    labelId='method-select-label'
                    value={method}
                    label='Способ связи'
                    onChange={e => {
                        setMethod(e.target.value as EMethods)
                    }}
                >
                    <MenuItem value={EMethods.noMatter}>{METHODS[EMethods.noMatter]}</MenuItem>
                    <MenuItem value={EMethods.phone}>{METHODS[EMethods.phone]}</MenuItem>
                    <MenuItem value={EMethods.whatsApp}>{METHODS[EMethods.whatsApp]}</MenuItem>
                    <MenuItem value={EMethods.telegram}>{METHODS[EMethods.telegram]}</MenuItem>
                </Select>
            </FormControl>
            <Stack spacing={0.5}>
                <TextField
                    label='Комментарий'
                    variant='outlined'
                    size='small'
                    inputProps={{
                        maxLength: 512,
                    }}
                    multiline
                    minRows={3}
                    maxRows={5}
                    value={comment}
                    onChange={e => {
                        setComment(e.target.value)
                    }}
                />
            </Stack>
            <Stack spacing={1.5} direction='row'>
                <Button
                    disabled={!name.trim() || phone.length !== 11 || !region}
                    color='primary'
                    fullWidth
                    variant='contained'
                    onClick={() => {
                        props.onSubmit({
                            id: booking?.id,
                            region,
                            name: name.trim(),
                            surname: surname.trim(),
                            phone,
                            comment: comment.trim(),
                            type: EBookingTypes.training,
                            method,
                        })
                    }}
                >
                    {booking ? 'Сохранить' : 'Отправить'}
                </Button>
                {canDelete && (
                    <Button
                        color='primary'
                        sx={{
                            backgroundColor: COLORS.colorBrown,
                        }}
                        variant='contained'
                        aria-label='delete'
                        onClick={() => {
                            setOpenDeleteDialog(true)
                        }}
                    >
                        <DeleteIcon />
                    </Button>
                )}
            </Stack>
            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false)
                }}
            >
                <DialogTitle>Отменить запись?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Вы уверены, что хотите отменить запись?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            setOpenDeleteDialog(false)
                        }}
                    >
                        Нет
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            deleteBooking({booking, type: EBookingTypes.training}, {onSuccess: props.onCancel})
                        }}
                    >
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

interface IProps {
    canDelete?: boolean
    booking?: IBooking
    onSubmit: (booking: IBooking) => void
    onCancel?: () => void
}
