import {Expo, gsap} from 'gsap'
import {
    IUniform,
    LinearFilter,
    Mesh,
    OrthographicCamera,
    PlaneBufferGeometry,
    Scene,
    ShaderMaterial,
    Texture,
    TextureLoader,
    Vector4,
    WebGLRenderer,
} from 'three'

const vertex = `
varying vec2 vUv;
void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`

const fragment = `
varying vec2 vUv;

uniform float dispFactor;
uniform float dpr;
uniform sampler2D disp;
uniform sampler2D texture1;
uniform sampler2D texture2;
uniform float angle1;
uniform float angle2;
uniform float intensity;
uniform vec4 res;
uniform vec2 parent;

mat2 getRotM(float angle) {
  float s = sin(angle);
  float c = cos(angle);
  return mat2(c, -s, s, c);
}

void main() {
  vec4 disp = texture2D(disp, vUv);
  vec2 dispVec = vec2(disp.r, disp.g);

  vec2 uv = 0.5 * gl_FragCoord.xy / (res.xy) ;
  vec2 myUV = (uv - vec2(0.5))*res.zw + vec2(0.5);

  vec2 distortedPosition1 = myUV + getRotM(angle1) * dispVec * intensity * dispFactor;
  vec2 distortedPosition2 = myUV + getRotM(angle2) * dispVec * intensity * (1.0 - dispFactor);
  vec4 _texture1 = texture2D(texture1, distortedPosition1);
  vec4 _texture2 = texture2D(texture2, distortedPosition2);
  gl_FragColor = mix(_texture1, _texture2, dispFactor);
}
`

export class ImageSlider {
    private tweenData: {tween: gsap.core.Tween; onComplete: () => void}
    private renderer: WebGLRenderer
    private options: IOptions
    private texturesProvider: TexturesProvider
    private uniforms: {[uniform: string]: IUniform}
    private scene: Scene
    private mesh: Mesh
    private camera: OrthographicCamera
    private duration = 1.2
    private easing = Expo.easeOut
    private onResize: () => void
    public cleared = false

    constructor(options: IOptions) {
        const {images} = options

        this.options = options
        this.texturesProvider = new TexturesProvider(images, 3)

        this.scene = new Scene()
        this.camera = new OrthographicCamera(0, 0, 0, 0, 1, 1000)
        this.camera.position.z = 1

        this.renderer = new WebGLRenderer({
            antialias: false,
            alpha: true,
        })

        this.renderer.setPixelRatio(2)
        this.renderer.setClearColor(0xffffff, 0)

        this.onResize = () => {
            this.setSize()
            this.render()
        }
    }

    async init() {
        const {texturesProvider, options} = this
        const {dispImage, parent} = options
        const commonAngle = Math.PI / 4 // 45 degrees by default, so grayscale images work correctly

        await texturesProvider.promise

        const mat = new ShaderMaterial({
            uniforms: {
                intensity: {value: 1},
                dispFactor: {value: 0.0},
                angle1: {value: commonAngle},
                angle2: {value: -commonAngle * 3},
                texture1: {value: texturesProvider.getFirst()},
                texture2: {value: null},
                disp: {value: await getTexture(dispImage)},
                res: {value: null},
                dpr: {value: window.devicePixelRatio},
            },
            vertexShader: vertex,
            fragmentShader: fragment,
            transparent: true,
            opacity: 1.0,
        })

        if (this.cleared) {
            return
        }

        this.uniforms = mat.uniforms
        this.mesh = new Mesh(new PlaneBufferGeometry(1, 1, 1, 1), mat)
        this.scene.add(this.mesh)

        this.setSize()
        parent.appendChild(this.renderer.domElement)
        this.render()
        window.addEventListener('resize', this.onResize)
    }

    next() {
        const {tweenData, texturesProvider, options, uniforms} = this
        const {onChange} = options
        const texture = this.texturesProvider.getNext()

        if (!texture) {
            return
        }

        onChange?.(texturesProvider.index)

        const onComplete = () => {
            uniforms.texture1.value = texture
            this.tweenData = null
        }

        if (tweenData) {
            tweenData.tween.kill()
            tweenData.onComplete()
        }

        uniforms.texture2.value = texture
        uniforms.dispFactor.value = 0

        const tween = gsap.to(uniforms.dispFactor, {
            value: 1,
            ease: this.easing,
            duration: this.duration,
            onUpdate: () => {
                this.render()
            },
            onComplete,
        })

        this.tweenData = {
            tween,
            onComplete,
        }
    }

    prev() {
        const {tweenData, texturesProvider, options, uniforms} = this
        const {onChange} = options
        const texture = texturesProvider.getPrev()

        if (!texture) {
            return
        }

        onChange?.(texturesProvider.index)

        const onComplete = () => {
            uniforms.texture2.value = texture
            this.tweenData = null
        }

        if (tweenData) {
            tweenData.tween.kill()
            tweenData.onComplete()
        }

        uniforms.texture1.value = texture
        uniforms.dispFactor.value = 1

        const tween = gsap.to(uniforms.dispFactor, {
            value: 0,
            ease: this.easing,
            duration: this.duration,
            onUpdate: () => {
                this.render()
            },
            onComplete,
        })

        this.tweenData = {
            tween,
            onComplete,
        }
    }

    clear() {
        const {parent} = this.options

        if (this.renderer.domElement.parentNode === parent) {
            parent.removeChild(this.renderer.domElement)
        }

        this.tweenData?.tween.kill()
        this.renderer.renderLists.dispose()
        window.removeEventListener('resize', this.onResize)
        this.cleared = true
    }

    private render() {
        this.renderer.render(this.scene, this.camera)
    }

    private setSize() {
        const {parent} = this.options
        const image = this.texturesProvider.getFirst().image
        const imageWidth = image.width
        const imageHeight = image.height
        const width = parent.offsetWidth
        const height = (width / imageWidth) * imageHeight

        const halfWidth = width / 2
        const halfHeight = height / 2

        this.camera.left = -halfWidth
        this.camera.right = halfWidth
        this.camera.top = halfHeight
        this.camera.bottom = -halfHeight
        this.camera.updateProjectionMatrix()

        this.mesh.geometry = new PlaneBufferGeometry(width, height, 1, 1)
        this.uniforms.res.value = new Vector4(width, height, 1, 1)
        this.renderer.setSize(width, height)
    }
}

class TexturesProvider {
    private textures: Texture[]
    private minCount: number
    private promises: Promise<Texture>[]
    promise: Promise<void>
    index: number

    constructor(private images: string[], minCount?: number) {
        this.textures = []
        this.index = 0
        this.minCount = minCount || 1
        this.init()
    }

    private init() {
        this.promises = []

        for (const item of this.images.slice(0, this.minCount)) {
            this.promises.push(getTexture(item))
        }

        this.promise = Promise.all(this.promises).then(textures => {
            this.textures = textures
        })
    }

    getNext() {
        const {minCount} = this
        const loadedCount = this.promises.length
        const allCount = this.images.length
        const nextIndex = this.index + 1

        if (nextIndex > allCount) {
            return null
        }

        if (loadedCount < nextIndex + minCount && loadedCount < allCount) {
            const image = this.images[loadedCount]
            const promise = getTexture(image)

            promise.then(texture => {
                this.textures.push(texture)
            })

            this.promises.push(promise)
        }

        const texture = this.textures[this.index + 1]

        if (!texture) {
            return null
        }

        this.index++
        return texture
    }

    getPrev() {
        if (this.index - 1 < 0) {
            return null
        }

        this.index--

        return this.textures[this.index]
    }

    getFirst() {
        return this.textures[0]
    }
}

const loader = new TextureLoader()
const texturesCache: {[key: string]: Texture} = {}

loader.crossOrigin = ''

async function getTexture(image: string) {
    let texture = texturesCache[image]

    if (texture) {
        return texture
    }

    texture = await loader.loadAsync(image)

    texture.magFilter = LinearFilter
    texture.minFilter = LinearFilter

    texturesCache[image] = texture

    return texture
}

interface IOptions {
    parent: HTMLElement
    dispImage: string
    images: string[]
    onChange?: (index: number) => void
}
