import {useMutation, useQuery, useQueryClient} from 'react-query'
import axios from 'axios'

import {ISettings} from 'shared/consts'

export const useSettings = () => {
    return useQuery<ISettings>(['useSettings'], async () => {
        return await getSettings()
    })
}

export const useSetSettings = () => {
    const queryClient = useQueryClient()

    return useMutation<unknown, unknown, ISettings>(
        async setting => {
            await setSetting(setting)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useSettings')
            },
        },
    )
}

const getSettings = async (): Promise<ISettings> => {
    const {data} = await axios({
        method: 'get',
        url: '/api/v1/settings',
    })

    return data
}

const setSetting = async (params: ISettings): Promise<void> => {
    await axios({
        method: 'post',
        url: '/api/v1/settings',
        data: params,
    })
}
