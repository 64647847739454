import {useCallback, useEffect, useState} from 'react'

export function useShowAndHide() {
    const [isHidden, setIsHidden] = useState(false)
    const [hideTimer, setHideTimer] = useState(null)

    const setVisible = useCallback(() => {
        clearTimeout(hideTimer)
        setIsHidden(false)

        const timer = setTimeout(() => {
            setIsHidden(true)
        }, 1000)

        setHideTimer(timer)
    }, [hideTimer, setHideTimer, setIsHidden])

    useEffect(() => {
        return () => {
            clearTimeout(hideTimer)
        }
    }, [hideTimer])

    return {
        setVisible,
        isHidden,
    }
}
