import React from 'react'
import Stack from '@mui/material/Stack'

import {Header} from 'front/components/header/Header'

import {Memo} from '../../components/memo/Memo'
import styles from './memoPage.css'

export function MemoPage() {
    return (
        <Stack className={styles.memoPage} spacing={2}>
            <Header text={'Памятка по подготовке'} href='/' />
            <Memo />
        </Stack>
    )
}
