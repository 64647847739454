import React, {useMemo, useState} from 'react'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'

import {IPortfolio} from 'shared/consts'
import {usePortfolios} from 'front/queries/portfolio'
import {Header} from 'front/components/header/Header'
import {Slider} from 'front/components/slider/Slider'

import styles from './portfolioPage.css'

export function PortfolioPage() {
    const {data: portfolios} = usePortfolios()

    const [portfolio, setPortfolio] = useState<IPortfolio>(null)

    const galery = useMemo(() => {
        if (!portfolios) {
            return null
        }

        return (
            <Stack spacing={2}>
                {portfolios
                    .filter(item => item.images.length > 0)
                    .map(item => (
                        <div key={item.id}>
                            <div className={styles.header}>{item.name}</div>
                            <Fade in timeout={500}>
                                <div className={styles.galery}>
                                    {item.images.slice(0, 4).map(imageId => (
                                        <img
                                            key={imageId}
                                            className={styles.photo}
                                            src={`/api/v1/images/${imageId}?small=1`}
                                            onClick={() => {
                                                setPortfolio(item)
                                            }}
                                        />
                                    ))}
                                </div>
                            </Fade>
                        </div>
                    ))}
            </Stack>
        )
    }, [portfolios])

    return (
        <div className={styles.portfolioPage}>
            {!portfolio && (
                <Stack spacing={2}>
                    <Header text={'Портфолио'} href='/' />
                    {galery}
                </Stack>
            )}
            {portfolio && (
                <Stack spacing={2}>
                    <Header
                        text={portfolio.name}
                        onBack={() => {
                            setPortfolio(null)
                        }}
                    />
                    <Slider images={portfolio.images.map(imageId => `/api/v1/images/${imageId}`)} />
                </Stack>
            )}
        </div>
    )
}
