import {combineReducers, configureStore, EnhancedStore, Reducer, ReducersMapObject, Slice} from '@reduxjs/toolkit'

import initReducer, {State as InitState} from './initSlice'

let storeData: {
    store: EnhancedStore
    reducerManager: {
        add: (key: string, reducer: Reducer) => void
        remove: (key: string) => void
    }
    slicesMap: ISlicesMap
}

export function getStoreData(preloadedState?: TRootState, force?: boolean) {
    if (!force && storeData) {
        return storeData
    }

    const initialReducers: ReducersMapObject = {
        init: initReducer,
    }

    const store = configureStore({
        reducer: combineReducers(initialReducers),
        preloadedState,
        /* middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                serializableCheck: {
                    ignoreActions: true,
                },
            });
        }, */
    })

    const reducers = {...initialReducers}

    storeData = {
        store,
        reducerManager: {
            add: (key: string, reducer: Reducer) => {
                if (!key || reducers[key]) {
                    return
                }

                reducers[key] = reducer

                storeData.store.replaceReducer(combineReducers(reducers))
            },
            remove: (key: string) => {
                if (!key || !reducers[key]) {
                    return
                }

                delete reducers[key]
                storeData.store.replaceReducer(combineReducers(reducers))
            },
        },
        slicesMap: {},
    }

    return storeData
}

export type TRootState = {
    init: InitState
}

type ISlicesMap = Record<
    string,
    {
        slice: Slice
        usedCount: number
    }
>
