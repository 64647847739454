import React from 'react'
import {useNavigate} from 'react-router-dom'
import cap from 'assets/images/pictogram/about/cap.png'
import clock from 'assets/images/pictogram/about/clock.png'
import diamond from 'assets/images/pictogram/about/diamond.png'
import earrings from 'assets/images/pictogram/about/earrings.png'
import rain from 'assets/images/pictogram/about/rain.png'
import slipper from 'assets/images/pictogram/about/slipper.png'
import wedding1 from 'assets/images/portfolioMain/1.jpg'
import wedding2 from 'assets/images/portfolioMain/2.jpg'
import wedding3 from 'assets/images/portfolioMain/3.jpg'
import wedding4 from 'assets/images/portfolioMain/4.jpg'
import about from 'assets/images/portfolioMain/about.jpg'
import authorImg from 'assets/images/portfolioMain/author.jpg'
import brows from 'assets/images/portfolioMain/brows.jpg'
import image from 'assets/images/portfolioMain/image.jpg'
import training from 'assets/images/portfolioMain/training.jpg'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import {ECountry} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {GlobalContext} from 'front/globalSlice'
import {useSlice} from 'front/sliceHook'
import {useCountry, useShowSections} from 'front/hooks/useCountry'
// import rogovImg from 'assets/images/rogov2.png'
import {Comments} from 'front/components/comments/Comments'
import {Contacts} from 'front/components/contacts/Contacts'
import {Image} from 'front/components/image/Image'

import {Animation} from './components/animation/Animation'
import styles from './mainPage.css'

export function MainPage() {
    const {actions} = useSlice(GlobalContext)
    const {setIsLoading} = actions
    const country = useCountry()

    const navigate = useNavigate()
    const showSections = useShowSections()

    return (
        <Stack className={styles.mainPage} spacing={3}>
            <div className={styles.section}>
                <Image
                    src={authorImg}
                    width={1024}
                    height={1539}
                    preLoad={() => {
                        setIsLoading(true)
                    }}
                    onLoad={() => {
                        setIsLoading(false)
                    }}
                >
                    <div className={styles.textBackground}>
                        <Animation />
                    </div>
                    <Button
                        color='secondary'
                        variant='outlined'
                        sx={{
                            position: 'absolute',
                            bottom: 20,
                            width: 148,
                            left: 'calc(50% - 74px)',
                        }}
                        onClick={() => {
                            sendEvent('mainBookBtn1')
                            navigate('/booking')
                        }}
                    >
                        Записаться
                    </Button>
                </Image>
            </div>
            <Stack className={styles.section} spacing={2}>
                <div className={styles.header}>Свадебный образ</div>
                <div className={styles.sectionText}>
                    Создам ваш особенный образ: легкий, женственный, очаровательный. Продумаю все детали - а вам
                    останется только наслаждаться этим счастливым днем!
                </div>
                <div className={styles.galery}>
                    <Image src={wedding1} width={500} height={751} />
                    <Image src={wedding2} width={500} height={751} />
                    <Image src={wedding3} width={500} height={751} />
                    <Image src={wedding4} width={500} height={751} />
                </div>
                <Button
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                        sendEvent('mainWeddingBtn')
                        navigate('/wedding')
                    }}
                >
                    Подробнее
                </Button>
            </Stack>
            <Divider />
            <Stack className={styles.section} spacing={2}>
                <div className={styles.header}>Вечерний и дневной образ</div>
                <div className={styles.sectionText}>
                    Создам образ на любое мероприятие: выпускной, юбилей, важную встречу или фотосессию. Подчеркну вашу
                    индивидуальность и уникальную красоту. Правил не существует - сделаем все так, как хотите именно вы!
                </div>
                <Image src={image} width={828} height={817}>
                    <div className={styles.imageForeground} />
                    <Button
                        className={styles.imageButton}
                        color='secondary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('mainImageBtn')
                            navigate('/image')
                        }}
                    >
                        Подробнее
                    </Button>
                </Image>
            </Stack>
            <Divider />
            <Stack className={styles.section} spacing={2}>
                <div className={styles.header}>Обо мне</div>
                <div
                    className={styles.sectionText}
                    itemScope
                    itemType='http://schema.org/Person'
                    itemRef='contacts logo_url address'
                >
                    Меня зовут <span itemProp='name'>Валентина Орлова</span>.<br />Я -{' '}
                    <span itemProp='jobTitle'>визажист</span> и <span itemProp='jobTitle'>стилист</span> по прическам.
                    {country === ECountry.CY
                        ? ' Работаю на Кипре в Пафосе, а также выезжаю в другие города. '
                        : ' Работаю в Москве и в Коломне. '}
                    Постоянно совершенствую навыки и бесконечно люблю свою профессию. Мне доверили свои сборы больше
                    1000 клиенток. Для меня важно создать образ, который не изменит вас до неузнаваемости, а подчеркнет
                    вашу индивидуальность.
                </div>
                <Image src={about} width={1024} height={1539}>
                    <div className={styles.imageForeground} />
                    <Button
                        className={styles.imageButton}
                        color='secondary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('mainAboutBtn')
                            navigate('/about')
                        }}
                    >
                        Подробнее
                    </Button>
                    {/* <img
                        className={styles.rogov}
                        src={rogovImg}
                    /> */}
                </Image>
                <div className={styles.iconsGalery}>
                    <div className={styles.iconWrap}>
                        <img className={styles.icon} src={diamond} />
                        <div className={styles.iconText}>Создам образ лучшей косметикой и инструментами</div>
                    </div>
                    <div className={styles.iconWrap}>
                        <img className={styles.icon} src={earrings} />
                        <div className={styles.iconText}>Помогу с подбором аксессуаров</div>
                    </div>
                    <div className={styles.iconWrap}>
                        <img className={styles.icon} src={rain} />
                        <div className={styles.iconText}>Сделаю стойкий образ</div>
                    </div>
                    <div className={styles.iconWrap}>
                        <img className={styles.icon} src={clock} />
                        <div className={styles.iconText}>Приеду в удобное вам время</div>
                    </div>
                    <div className={styles.iconWrap}>
                        <img className={styles.icon} src={cap} />
                        <div className={styles.iconText}>Использую самые актуальные техники</div>
                    </div>
                    <div className={styles.iconWrap}>
                        <img className={styles.icon} src={slipper} />
                        <div className={styles.iconText}>Со мной легко и комфортно</div>
                    </div>
                </div>
            </Stack>
            {showSections.brows && (
                <>
                    <Divider />
                    <Stack className={styles.section} spacing={2}>
                        <div className={styles.header}>Оформление бровей</div>
                        <div className={styles.sectionText}>
                            Сделаю ваши брови красивыми и ухоженными с помощью процедур коррекции, окрашивания и
                            долговременной укладки.
                        </div>
                        <Image src={brows} width={944} height={919}>
                            <div className={styles.imageForeground} />
                            <Button
                                className={styles.imageButton}
                                color='secondary'
                                variant='outlined'
                                onClick={() => {
                                    sendEvent('mainBrowsBtn')
                                    navigate('/brows')
                                }}
                            >
                                Подробнее
                            </Button>
                        </Image>
                    </Stack>
                </>
            )}
            <Divider />
            <Stack className={styles.section} spacing={2}>
                <div className={styles.header}>Обучение</div>
                <div className={styles.sectionText}>
                    Проведу индивидуальное обучение, чтобы вы научились делать себе макияж на каждый день.
                    <br />В этот авторский курс я собрала весь мой 10-летний опыт, чтобы вы могли дополнить любой свой
                    образ безупречным макияжем.
                </div>
                <Image src={training} width={1024} height={1328}>
                    <div className={styles.imageForegroundLow} />
                    <Button
                        className={styles.imageButton}
                        color='secondary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('mainTrainingBtn')
                            navigate('/training')
                        }}
                    >
                        Подробнее
                    </Button>
                </Image>
            </Stack>
            <Divider />
            <Stack className={styles.section} spacing={2}>
                <div className={styles.header}>Отзывы</div>
                <Comments type='main' event='commentsMain' />
                <Button
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                        sendEvent('mainBookBtn2')
                        navigate('/booking')
                    }}
                >
                    Записаться
                </Button>
            </Stack>
            <Divider />
            <Stack className={styles.section} spacing={2}>
                <div className={styles.header}>Контакты</div>
                <Contacts />
            </Stack>
            <Divider />
        </Stack>
    )
}
