import {useMutation, useQuery, useQueryClient} from 'react-query'
import axios from 'axios'

import {EBookingTypes, ERegions, IBooking, IInterval} from 'shared/consts'

export const useBookingsBrows = () => {
    return useQuery<IBooking[]>(['useBookingsBrows'], async () => {
        return await getBookingsBrows()
    })
}

export const useBookingsImage = (isAdmin: boolean) => {
    return useQuery<IBooking[]>(
        ['useBookingsImage'],
        async () => {
            return await getBookingsImage()
        },
        {
            enabled: isAdmin,
        },
    )
}

export const useBookingsTraining = (isAdmin: boolean) => {
    return useQuery<IBooking[]>(
        ['useBookingsTraining'],
        async () => {
            return await getBookingsTraining()
        },
        {
            enabled: isAdmin,
        },
    )
}

export const useBrowsBooking = (date: number, interval: IInterval, region: ERegions) => {
    const {data: bookings} = useBookingsBrows()

    return useQuery<IBooking>(['useBrowsBooking', bookings, date, interval, region], () => {
        if (!date || !interval) {
            return null
        }

        for (const booking of bookings) {
            if (
                booking.region === region &&
                booking.date === date &&
                (booking.interval.from === interval.from || booking.interval.to === interval.to)
            ) {
                return booking
            }
        }

        return null
    })
}

export const useAddBooking = () => {
    const queryClient = useQueryClient()

    return useMutation<IBooking, unknown, IBooking>(
        async booking => {
            await addBooking(booking)
            return booking
        },
        {
            onSuccess: booking => {
                switch (booking.type) {
                    case EBookingTypes.image:
                        queryClient.invalidateQueries('useBookingsImage')
                        break

                    case EBookingTypes.brows:
                        queryClient.invalidateQueries('useBookingsBrows')
                        break

                    case EBookingTypes.training:
                        queryClient.invalidateQueries('useBookingsTraining')
                        break
                }
            },
        },
    )
}

export const useDeleteBooking = () => {
    const queryClient = useQueryClient()

    return useMutation<EBookingTypes, unknown, {booking: IBooking; type: EBookingTypes}>(
        async data => {
            await deleteBooking(data.booking.id)

            return data.type
        },
        {
            onSuccess: type => {
                switch (type) {
                    case EBookingTypes.image:
                        queryClient.invalidateQueries('useBookingsImage')
                        break

                    case EBookingTypes.brows:
                        queryClient.invalidateQueries('useBookingsBrows')
                        queryClient.invalidateQueries('useHours')
                        break

                    case EBookingTypes.training:
                        queryClient.invalidateQueries('useBookingsTraining')
                        break
                }
            },
        },
    )
}

const getBookingsBrows = async (): Promise<IBooking[]> => {
    const {data} = await axios({
        method: 'get',
        url: '/api/v1/bookings/brows',
    })

    return data
}

const getBookingsImage = async (): Promise<IBooking[]> => {
    const {data} = await axios({
        method: 'get',
        url: '/api/v1/bookings/image',
    })

    return data
}

const getBookingsTraining = async (): Promise<IBooking[]> => {
    const {data} = await axios({
        method: 'get',
        url: '/api/v1/bookings/training',
    })

    return data
}

const addBooking = async (params: IBooking): Promise<IBooking> => {
    const {data} = await axios({
        method: 'post',
        url: '/api/v1/bookings',
        data: params,
    })

    return data
}

const deleteBooking = async (id: number) => {
    await axios({
        method: 'delete',
        url: `/api/v1/bookings/${id}`,
    })
}
