import React, {useMemo} from 'react'
import moment from 'moment'
import Delete from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import {getPhoneString} from 'shared/utils'
import {useDeleteFeedback, useFeedbacks} from 'front/queries/feedback'
import {useIsAdmin} from 'front/queries/user'
import {Header} from 'front/components/header/Header'

import styles from './adminFeedbackPage.css'

export function AdminFeedbackPage() {
    const isAdmin = useIsAdmin()
    const {data: feedbacks} = useFeedbacks()
    const {mutate: deleteFeedback} = useDeleteFeedback()

    const list = useMemo(() => {
        if (!feedbacks?.length) {
            return <div className={styles.empty}>Нет отзывов</div>
        }

        return feedbacks
            .sort((a, b) => a.date - b.date)
            .map(feedback => {
                return (
                    <div className={styles.itemWrap} key={feedback.id}>
                        <Stack spacing={1}>
                            <div className={styles.itemLabel}>
                                {moment(feedback.date * 1000).format('DD MMM')} {feedback.name}
                            </div>
                            <div>
                                <span className={styles.subLabel}>Телефон:</span> {getPhoneString(feedback.phone)}
                            </div>
                            <div className={styles.itemComment}>
                                <span className={styles.subLabel}>Сообщение:</span> {feedback.comment}
                            </div>
                        </Stack>
                        <Button
                            className={styles.button}
                            variant='contained'
                            onClick={() => {
                                deleteFeedback(feedback.id)
                            }}
                        >
                            <Delete />
                        </Button>
                    </div>
                )
            })
    }, [feedbacks])

    if (!isAdmin) {
        return null
    }

    return (
        <Stack className={styles.adminFeedbackPage} spacing={2}>
            <Header text={'Обратная связь'} href='/admin' />
            {list}
        </Stack>
    )
}
