import React, {useState} from 'react'
import moment, {Moment} from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'

import {COLORS, DATE_MASK, EBookingTypes, EImages, EMethods, ERegions, IBooking, IMAGES, METHODS} from 'shared/consts'
import {useDeleteBooking} from 'front/queries/bookings'
import {CitySelect} from 'front/components/citySelect/CitySelect'
import {Phone} from 'front/components/phone/Phone'

import styles from './bookingImageForm.css'

export function BookingImageForm(props: IProps) {
    const {booking, canDelete} = props

    const [date, setDate] = useState<Moment>(booking ? moment(booking.date * 1000) : null)
    const [name, setName] = useState(booking?.name || '')
    const [surname, setSurname] = useState(booking?.surname || '')
    const [phone, setPhone] = useState(booking?.phone || '')
    const [comment, setComment] = useState(booking?.comment || '')
    const [region, setRegion] = useState<ERegions>(booking?.region || undefined)
    const [method, setMethod] = useState<EMethods>(booking?.method || EMethods.noMatter)
    const [imageType, setImageType] = useState<EImages>(booking?.imageType || null)

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    const {mutate: deleteBooking} = useDeleteBooking()

    return (
        <Stack className={styles.bookingImagePage} spacing={2}>
            <div className={styles.head}>Заполните форму, и мы свяжемся с Вами для уточнения деталей</div>
            <TextField
                required
                label='Имя'
                variant='outlined'
                size='small'
                inputProps={{
                    maxLength: 50,
                }}
                value={name}
                onChange={e => {
                    setName(e.target.value)
                }}
            />
            <TextField
                label='Фамилия'
                variant='outlined'
                size='small'
                inputProps={{
                    maxLength: 50,
                }}
                value={surname}
                onChange={e => {
                    setSurname(e.target.value)
                }}
            />
            <Phone setPhone={setPhone} phone={phone} />
            <CitySelect required city={region} setCity={setRegion} />
            <FormControl required size='small'>
                <InputLabel id='image-type-select-label'>Услуга</InputLabel>
                <Select
                    labelId='image-type-select-label'
                    value={imageType || ''}
                    label='Услуга'
                    onChange={e => {
                        setImageType(e.target.value as EImages)
                    }}
                >
                    <MenuItem value={EImages.wedding}>{IMAGES[EImages.wedding]}</MenuItem>
                    <MenuItem value={EImages.evening}>{IMAGES[EImages.evening]}</MenuItem>
                    <MenuItem value={EImages.other}>{IMAGES[EImages.other]}</MenuItem>
                </Select>
            </FormControl>
            <FormControl size='small'>
                <InputLabel id='method-select-label'>Способ связи</InputLabel>
                <Select
                    labelId='method-select-label'
                    value={method}
                    label='Способ связи'
                    onChange={e => {
                        setMethod(e.target.value as EMethods)
                    }}
                >
                    <MenuItem value={EMethods.noMatter}>{METHODS[EMethods.noMatter]}</MenuItem>
                    <MenuItem value={EMethods.phone}>{METHODS[EMethods.phone]}</MenuItem>
                    <MenuItem value={EMethods.whatsApp}>{METHODS[EMethods.whatsApp]}</MenuItem>
                    <MenuItem value={EMethods.telegram}>{METHODS[EMethods.telegram]}</MenuItem>
                </Select>
            </FormControl>
            <DatePicker
                minDate={moment().add(1, 'day')}
                label='Дата'
                mask={DATE_MASK}
                renderInput={params => <TextField {...params} size='small' required />}
                value={date}
                onChange={date => {
                    setDate(date)
                }}
                cancelText='Отмена'
            />
            <Stack spacing={0.5}>
                <TextField
                    required
                    label='Комментарий'
                    variant='outlined'
                    size='small'
                    inputProps={{
                        maxLength: 512,
                    }}
                    multiline
                    minRows={3}
                    maxRows={5}
                    value={comment}
                    onChange={e => {
                        setComment(e.target.value)
                    }}
                />
                <div className={styles.desc}>Напишете коротко о своих пожеланиях</div>
            </Stack>
            <Stack spacing={1.5} direction='row'>
                <Button
                    disabled={!name.trim() || phone.length !== 11 || !date || !comment || !region || !imageType}
                    color='primary'
                    fullWidth
                    variant='contained'
                    onClick={() => {
                        props.onSubmit({
                            id: booking?.id,
                            date: date.unix(),
                            region,
                            name: name.trim(),
                            surname: surname.trim(),
                            phone,
                            comment: comment.trim(),
                            type: EBookingTypes.image,
                            method,
                            imageType,
                        })
                    }}
                >
                    {booking ? 'Сохранить' : 'Отправить'}
                </Button>
                {canDelete && (
                    <Button
                        color='primary'
                        sx={{
                            backgroundColor: COLORS.colorBrown,
                        }}
                        variant='contained'
                        aria-label='delete'
                        onClick={() => {
                            setOpenDeleteDialog(true)
                        }}
                    >
                        <DeleteIcon />
                    </Button>
                )}
            </Stack>
            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false)
                }}
            >
                <DialogTitle>Отменить запись?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Вы уверены, что хотите отменить запись?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            setOpenDeleteDialog(false)
                        }}
                    >
                        Нет
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            deleteBooking({booking, type: EBookingTypes.image}, {onSuccess: props.onCancel})
                        }}
                    >
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

interface IProps {
    canDelete?: boolean
    booking?: IBooking
    onCancel?: () => void
    onSubmit: (booking: IBooking) => void
}
