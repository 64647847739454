import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import image from 'assets/images/pages/about.jpg'
import imageRogov1 from 'assets/images/pages/about/rogov1.jpg'
import imageRogov2 from 'assets/images/pages/about/rogov2.jpg'
import imageWork from 'assets/images/pages/about/work.jpg'
import cap from 'assets/images/pictogram/about/cap.png'
import clock from 'assets/images/pictogram/about/clock.png'
import diamond from 'assets/images/pictogram/about/diamond.png'
import earrings from 'assets/images/pictogram/about/earrings.png'
import rain from 'assets/images/pictogram/about/rain.png'
import slipper from 'assets/images/pictogram/about/slipper.png'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import {ECountry} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {usePortfolios} from 'front/queries/portfolio'
import {useCountry} from 'front/hooks/useCountry'
import {Header} from 'front/components/header/Header'
import {Image} from 'front/components/image/Image'
import {Slider} from 'front/components/slider/Slider'

import styles from './aboutPage.css'

export function AboutPage() {
    const {data: portfolios} = usePortfolios()
    const navigate = useNavigate()
    const country = useCountry()

    const portfolioAbout = useMemo(() => {
        if (!portfolios) {
            return null
        }

        return portfolios.find(item => item.name === 'about')
    }, [portfolios])

    const portfolioCert = useMemo(() => {
        if (!portfolios) {
            return null
        }

        return portfolios.find(item => item.name === 'cert')
    }, [portfolios])

    return (
        <Stack className={styles.aboutPage} spacing={1}>
            <Header text={'Обо мне'} href='/' />
            <Stack spacing={4}>
                <Stack spacing={2}>
                    <Image src={image} width={1024} height={1539} />
                    <Stack className={styles.text} spacing={1}>
                        <div>Меня зовут Валентина Орлова.</div>
                        <div>
                            Я - визажист и стилист по прическам.
                            {country === ECountry.CY
                                ? ' Работаю на Кипре в Пафосе, а также выезжаю в другие города. '
                                : ' Работаю в Москве и в Коломне. '}
                            Создала более 1000 прекрасных образов.
                        </div>
                        <div>
                            Работала гримером и визажистом шоу-преображения на{' '}
                            <span className={styles.strong}>СТС</span>, получила награду{' '}
                            <span className={styles.imp}>«Лучший стилист»</span> по мнению Александра Рогова, известного
                            стилиста и эксперта моды.
                        </div>
                        <Image src={imageRogov1} width={1024} height={778} />
                        <Image src={imageRogov2} width={1024} height={835} />
                        <div>
                            Участвовала в съемках сериалов и кино в качестве помощника художника по гриму, работала с
                            российскими актрисами.
                        </div>
                        <div>
                            На данный момент специализируюсь на клиентских и свадебных образах. Люблю создавать нежные и
                            легкие текстуры - простые, но при этом роскошные. В макияже делаю акцент на чистую сияющую
                            кожу.
                        </div>
                        <div>Работаю на люксовой косметике и использую самые технологичные инструменты для волос.</div>
                        {portfolioAbout && (
                            <Slider
                                images={portfolioAbout.images.map(imageId => `/api/v1/images/${imageId}`)}
                                event='sliderAbout1'
                            />
                        )}
                        <div>
                            Образ, который я создам, не изменит вас до неузнаваемости, а лишь украсит, подчеркнет вашу
                            уникальную красоту.
                        </div>
                        <Stack spacing={2}>
                            <div>
                                <span className={styles.strong}>
                                    Буду рада видеть вас у себя в студии или стать вашим свадебным стилистом!
                                </span>
                            </div>
                            <Button
                                color='primary'
                                variant='outlined'
                                onClick={() => {
                                    sendEvent('aboutBookBtn1')
                                    navigate('/booking')
                                }}
                            >
                                Записаться
                            </Button>
                            <Image src={imageWork} width={1024} height={1320} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack spacing={3}>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Создам образ лучшей косметикой и инструментами</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={diamond} />
                            <span>
                                Работаю на люксовой косметике и использую самые технологичные инструменты для волос
                            </span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Помогу с подбором аксессуаров</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={earrings} />
                            <span>Всегда готова дать свои рекомендации по аксессуарам и образу в целом</span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Сделаю стойкий образ</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={rain} />
                            <span>Создам образ, который будет выглядеть красиво и аккуратно весь день</span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Приеду в удобное вам время</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={clock} />
                            <span>
                                Подготовлю вас точно к нужному времени в студии / у вас дома / в отеле / за городом
                            </span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Использую самые актуальные техники</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={cap} />
                            <span>Постоянно учусь и совершенствую навыки</span>
                        </div>
                    </Stack>
                    <Stack spacing={2}>
                        <div className={styles.iconHeader}>Со мной легко и комфортно</div>
                        <div className={styles.content}>
                            <img className={styles.icon} src={slipper} />
                            <span>Забочусь не только о качестве работы, но и о создании дружеской атмосферы</span>
                        </div>
                    </Stack>
                </Stack>
                <Stack spacing={2}>
                    {portfolioCert && (
                        <Slider
                            images={portfolioCert.images.map(imageId => `/api/v1/images/${imageId}`)}
                            event='sliderAbout2'
                        />
                    )}
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('aboutBookBtn2')
                            navigate('/booking')
                        }}
                    >
                        Записаться
                    </Button>
                    <Divider />
                </Stack>
            </Stack>
        </Stack>
    )
}
