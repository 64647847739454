import React from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import {useAddPortfolio, usePortfolios} from 'front/queries/portfolio'
import {useIsAdmin} from 'front/queries/user'
import {Header} from 'front/components/header/Header'

import styles from './adminPortfolioPage.css'
import {PortfolioItem} from './portfolioItem/PortfolioItem'

export function AdminPortfolioPage() {
    const isAdmin = useIsAdmin()
    const {data: portfolios} = usePortfolios()
    const {mutate: addPortfolio} = useAddPortfolio()

    if (!isAdmin) {
        return null
    }

    return (
        <Stack className={styles.adminPortfolioPage} spacing={2}>
            <Header text={'Портфолио'} href='/admin' />
            <Stack spacing={4}>
                {portfolios?.map(item => (
                    <PortfolioItem
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        orderId={item.orderId}
                        images={item.images}
                    />
                ))}
            </Stack>
            <Button
                className={styles.button}
                variant='contained'
                onClick={() => {
                    const orderIds = portfolios.map(item => item.orderId)
                    let orderId = 1

                    if (orderIds.length) {
                        orderId = Math.max(...orderIds) + 1
                    }

                    addPortfolio({
                        name: 'Модель',
                        orderId,
                        images: [],
                    })
                }}
            >
                Добавить модель
            </Button>
        </Stack>
    )
}
