import React from 'react'
import {SxProps} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import {ECountry, ERegions, REGIONS} from 'shared/consts'
import {useCountry} from 'front/hooks/useCountry'

export function CitySelect(props: Props) {
    const {city, hasAll, setCity, required, sx, cities} = props
    const country = useCountry()

    return (
        <FormControl size='small' required={required} sx={sx}>
            <InputLabel>Город</InputLabel>
            <Select
                value={city || ''}
                label='Город'
                onChange={e => {
                    setCity(e.target.value as ERegions)
                }}
            >
                {hasAll && <MenuItem value={ERegions.all}>{REGIONS.all}</MenuItem>}
                {country === ECountry.RU &&
                    [
                        (!cities || cities.includes(ERegions.moscow)) && (
                            <MenuItem key={ERegions.moscow} value={ERegions.moscow}>
                                {REGIONS.moscow}
                            </MenuItem>
                        ),
                        (!cities || cities.includes(ERegions.kolomna)) && (
                            <MenuItem key={ERegions.kolomna} value={ERegions.kolomna}>
                                {REGIONS.kolomna}
                            </MenuItem>
                        ),
                    ].filter(Boolean)}
                {country === ECountry.CY &&
                    [
                        (!cities || cities.includes(ERegions.pafos)) && (
                            <MenuItem key={ERegions.pafos} value={ERegions.pafos}>
                                {REGIONS.pafos}
                            </MenuItem>
                        ),
                        (!cities || cities.includes(ERegions.limassol)) && (
                            <MenuItem key={ERegions.limassol} value={ERegions.limassol}>
                                {REGIONS.limassol}
                            </MenuItem>
                        ),
                        (!cities || cities.includes(ERegions.other)) && (
                            <MenuItem key={ERegions.other} value={ERegions.other}>
                                {REGIONS.other}
                            </MenuItem>
                        ),
                    ].filter(Boolean)}
            </Select>
        </FormControl>
    )
}

interface Props {
    city: ERegions
    setCity: (city?: ERegions) => void
    hasAll?: boolean
    required?: boolean
    cities?: ERegions[]
    sx?: SxProps
}
