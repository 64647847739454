import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import cn from 'clsx'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'

import {sendEvent} from 'front/frontUtils'

import styles from './contentslider.css'

const minSwipeDistance = 30

export function ContentSlider(props: IProps) {
    const {children, staticHeight, event} = props
    const count = React.Children.count(children)

    const [currentItem, setCurrentItem] = useState(0)
    const [contentHeight, setContentHeight] = useState(null)
    const isMounted = useRef(true)
    const [showContent, setShowContent] = useState(true)

    const [x, setX] = useState<number>(null)
    const [y, setY] = useState<number>(null)

    const sendEventCb = useCallback(() => {
        if (event) {
            sendEvent(event)
        }
    }, [event, sendEvent])

    const contentRef = useRef(null)

    const dots = useMemo(() => {
        const dots = []

        for (let i = 0; i < count; i++) {
            dots.push(<div key={i} className={cn(styles.dot, i === currentItem && styles.activeDot)} />)
        }

        return dots
    }, [currentItem])

    const onPrev = useCallback(() => {
        if (currentItem > 0) {
            setShowContent(false)

            sendEventCb()

            setTimeout(() => {
                if (!isMounted.current) {
                    return
                }

                setCurrentItem(currentItem - 1)
                setShowContent(true)
            }, 200)
        }
    }, [currentItem, setCurrentItem, setShowContent])

    const onNext = useCallback(() => {
        if (currentItem < count - 1) {
            setShowContent(false)

            sendEventCb()

            setTimeout(() => {
                if (!isMounted.current) {
                    return
                }

                setCurrentItem(currentItem + 1)
                setShowContent(true)
            }, 200)
        }
    }, [currentItem, setCurrentItem, setShowContent])

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (!staticHeight) {
            if (contentHeight === null) {
                setContentHeight('initial')
            } else {
                setContentHeight(`${contentRef.current.offsetHeight}px`)
            }
        }
    }, [showContent])

    return (
        <div
            className={styles.sliderWrap}
            onTouchStartCapture={e => {
                if (e.touches.length > 1) {
                    setX(null)
                    setY(null)
                } else {
                    setX(e.changedTouches[0].clientX)
                    setY(e.changedTouches[0].clientY)
                }
            }}
            onTouchMoveCapture={e => {
                if (!x || !y) {
                    return
                }

                const xDiff = x - e.changedTouches[0].clientX
                const yDiff = y - e.changedTouches[0].clientY
                const absXDiff = Math.abs(xDiff)
                const absYDiff = Math.abs(yDiff)

                if (absXDiff > absYDiff) {
                    if (absXDiff > minSwipeDistance) {
                        if (xDiff > 0) {
                            onNext()
                        } else {
                            onPrev()
                        }

                        setX(null)
                        setY(null)
                    }
                }
            }}
            onTouchEndCapture={() => {
                setX(null)
                setY(null)
            }}
        >
            <Stack direction='row' justifyContent='center' alignItems='center' className={styles.slider}>
                <div className={styles.buttonLeftWrap} onClick={onPrev}>
                    <Button
                        disabled={currentItem === 0}
                        className={cn(styles.buttonLeft)}
                        color='primary'
                        variant='outlined'
                    >
                        <ArrowBack className={styles.left} />
                    </Button>
                </div>
                <div
                    className={styles.contentWrap}
                    style={{
                        height: contentHeight,
                    }}
                >
                    <Fade in={showContent} timeout={400} ref={contentRef}>
                        {children[currentItem] as React.ReactElement}
                    </Fade>
                </div>
                <div className={styles.buttonRightWrap} onClick={onNext}>
                    <Button
                        disabled={currentItem === count - 1}
                        className={cn(styles.buttonRight)}
                        // edge='start'
                        color='primary'
                        variant='outlined'
                    >
                        <ArrowForward className={styles.right} />
                    </Button>
                </div>
            </Stack>
            <div className={styles.dots}>{dots}</div>
        </div>
    )
}

interface IProps {
    children: React.ReactChild[]
    staticHeight?: boolean
    event?: string
}
