import React, {useCallback, useEffect, useRef, useState} from 'react'
import cn from 'clsx'

import {round} from 'shared/utils'

import styles from './image.css'

export function Image(props: IProps) {
    const {src, height, width, className, onLoad, preLoad, children} = props
    const imgRef = useRef(null)

    const [isLoaded, setIsLoaded] = useState(false)

    const onLoadCb = useCallback(() => {
        setIsLoaded(true)
        onLoad?.()
    }, [onLoad])

    useEffect(() => {
        if (imgRef.current.complete) {
            onLoadCb()
        } else {
            preLoad?.()
        }
    }, [])

    return (
        <div
            className={cn(styles.imageWrap, className, isLoaded && styles.loaded)}
            style={{paddingBottom: `${round((height / width) * 100, 4)}%`}}
            itemScope
            itemType='http://schema.org/ImageObject'
        >
            <img
                ref={imgRef}
                className={styles.photo}
                itemProp='contentUrl'
                src={src}
                loading='lazy'
                onLoad={onLoadCb}
            />
            {isLoaded && children}
        </div>
    )
}

interface IProps {
    src: string
    width: number
    height: number
    className?: string
    children?: React.ReactChild[]
    onLoad?: () => void
    preLoad?: () => void
}
