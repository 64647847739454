import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import image from 'assets/images/pages/wedding.jpg'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import {EPriceListType} from 'shared/consts'
import {sendEvent} from 'front/frontUtils'
import {usePortfolios} from 'front/queries/portfolio'
import {Comments} from 'front/components/comments/Comments'
import {Faq} from 'front/components/faq/Faq'
import {Header} from 'front/components/header/Header'
import {Image} from 'front/components/image/Image'
import {PriceList} from 'front/components/priceList/PriceList'
import {Slider} from 'front/components/slider/Slider'

import styles from './weddingPage.css'

export function WeddingPage() {
    const navigate = useNavigate()

    const {data: portfolios} = usePortfolios()

    const portfolio = useMemo(() => {
        if (!portfolios) {
            return null
        }

        return portfolios.find(item => item.name === 'wedding')
    }, [portfolios])

    return (
        <Stack className={styles.weddingPage} spacing={2}>
            <Header text={'Свадебный образ'} href='/' />
            <Stack spacing={4}>
                <Stack spacing={2}>
                    <Image src={image} width={1024} height={1428} />
                    <Stack className={styles.text} spacing={1}>
                        <div>
                            Свадебный образ – это не только красивая прическа и макияж, но и отражение вашей внутренней
                            красоты, вашей личности, вашей истории любви.
                        </div>
                        <div>
                            Я люблю создавать стильные, легкие, женственные образы. Они максимально раскрывают
                            естественную красоту, остаются актуальными спустя время и великолепно смотрятся на свадебных
                            фото.
                        </div>
                    </Stack>
                    {portfolio && (
                        <Slider
                            images={portfolio.images.map(imageId => `/api/v1/images/${imageId}`)}
                            event='sliderWedding'
                        />
                    )}
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('weddingBookBtn1')
                            navigate('/booking/image')
                        }}
                    >
                        Записаться
                    </Button>
                    <Divider />
                </Stack>
                <Stack spacing={2}>
                    <div className={styles.header}>Услуги и цены</div>
                    <PriceList type={EPriceListType.wedding} event='priceWedding' />
                    <Divider />
                </Stack>
                <Stack spacing={2}>
                    <div className={styles.header}>Частые вопросы</div>
                    <Faq />
                    <Divider />
                </Stack>
                <Stack spacing={2}>
                    <div className={styles.header}>Отзывы</div>
                    <Comments type='wedding' event='commentsWedding' />
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            sendEvent('weddingBookBtn2')
                            navigate('/booking/image')
                        }}
                    >
                        Записаться
                    </Button>
                    <Divider />
                </Stack>
            </Stack>
        </Stack>
    )
}
