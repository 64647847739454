import React, {useContext, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createSlice, CreateSliceOptions, Slice} from '@reduxjs/toolkit'
import {bindActionCreators} from 'redux'

import {getStoreData} from 'front/store'

export function createUseSliceHook<Options extends CreateSliceOptions>(sliceData: Options) {
    return function (sliceName?: string) {
        const {reducerManager, slicesMap} = getStoreData()
        const name = useMemo(() => sliceName || sliceData.name, [sliceName])

        const sliceItem = useMemo(() => {
            let sliceItem = slicesMap[name]

            if (!sliceItem) {
                const slice = createSlice({
                    initialState: sliceData.initialState,
                    reducers: sliceData.reducers,
                    name,
                })

                sliceItem = slicesMap[name] = {
                    slice,
                    usedCount: 0,
                }

                reducerManager.add(name, sliceItem.slice.reducer)
            }

            sliceItem.usedCount++

            return sliceItem
        }, [])

        useEffect(() => {
            return () => {
                sliceItem.usedCount--

                if (sliceItem.usedCount === 0) {
                    delete slicesMap[name]
                    reducerManager.remove(name)
                }
            }
        }, [sliceItem])

        return sliceItem.slice as Slice<Options['initialState'], Options['reducers'], string>
    }
}

function useSliceState(name: string) {
    return useSelector((state: Record<string, unknown>) => state[name])
}

function useSliceActions(slice: Slice) {
    const dispatch = useDispatch()

    return useMemo(() => bindActionCreators(slice.actions, dispatch), [slice.actions, dispatch])
}

export function useSlice<SL extends Slice>(data: SL | React.Context<SL>) {
    let slice

    if ('Provider' in data) {
        slice = useContext(data)
    } else {
        slice = data
    }

    return {
        slice,
        state: useSliceState(slice.name) as ReturnType<SL['getInitialState']>,
        actions: useSliceActions(slice) as SL['actions'],
    }
}
