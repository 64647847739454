import React, {useEffect, useMemo, useState} from 'react'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import {ERegions, IInterval, TIME_FORMAT} from 'shared/consts'
import {toggleArrayItem} from 'shared/utils'
import {Header} from 'front/components/header/Header'

import {HoursTable} from '../hoursTable/HoursTable'
import styles from './editHoursForm.css'

const left = 9
const right = 21
const step = 30

const getMinutes = (time: string) => {
    const data = time.split(':')

    return Number(data[0]) * 60 + Number(data[1])
}

export function EditHoursForm(props: IProps) {
    const {date, intervals, onSubmit, onCancel, region} = props
    const mDate = moment(date * 1000)
    const [activeIntervals, setActiveIntervals] = useState<string[]>([])

    const intervalsData = useMemo(() => {
        const data = [...activeIntervals].map(item => {
            const [from, to] = item.split('-')

            return {
                from,
                to,
            }
        })

        if (data.length === 0) {
            return {
                intervals: [],
                hasHalfHour: false,
            }
        }

        if (data.length === 1) {
            return {
                intervals: data,
                hasHalfHour: true,
            }
        }

        data.sort((a, b) => {
            return getMinutes(a.from) - getMinutes(b.from)
        })

        const res: {from: string; to: string}[] = []
        let sequence = 0
        let hasHalfHour = false
        let from: string = null
        let to: string = null

        const add = () => {
            res.push({
                from,
                to,
            })

            if (sequence === 1) {
                hasHalfHour = true
            }
        }

        for (let i = 0; i < data.length; i++) {
            const item = data[i]

            if (from === null) {
                from = item.from
                to = item.to
                sequence = 1
            } else {
                if (to === item.from) {
                    sequence++
                    to = item.to
                } else {
                    add()

                    from = item.from
                    to = item.to
                    sequence = 1
                }
            }

            if (i === data.length - 1 || sequence === 2) {
                add()

                from = null
                to = null
            }
        }

        return {
            intervals: res,
            hasHalfHour,
        }
    }, [activeIntervals])

    const allIntervals = useMemo(() => {
        const from = moment().set('hour', left).startOf('hour')
        const to = moment()
            .set('hour', right - 1)
            .endOf('hour')
        const res = []

        const current = from.clone()

        while (current < to) {
            const curFrom = current.format(TIME_FORMAT)
            current.add(step, 'minute')
            const curTo = current.format(TIME_FORMAT)
            res.push({from: curFrom, to: curTo})
        }

        return res
    }, [])

    useEffect(() => {
        const aIntervals: string[] = []

        for (const {from, to} of intervals) {
            const mTo = moment(to, TIME_FORMAT)
            let mFrom = moment(from, TIME_FORMAT)
            let nextTo = mFrom.clone().add(step, 'minute')

            while (nextTo <= mTo) {
                aIntervals.push(`${mFrom.format(TIME_FORMAT)}-${nextTo.format(TIME_FORMAT)}`)

                mFrom = nextTo
                nextTo = nextTo.clone().add(step, 'minute')
            }
        }

        setActiveIntervals(aIntervals)
    }, [])

    return (
        <Stack className={styles.editForm} spacing={2}>
            <Header text={`${capitalize(mDate.format('dddd'))}, ${mDate.format('DD MMMM')}`} onBack={onCancel} />
            <HoursTable
                region={region}
                disableIfBooked
                date={date}
                intervals={allIntervals}
                activeIntervals={activeIntervals}
                onClick={item => {
                    setActiveIntervals(toggleArrayItem(activeIntervals, `${item.from}-${item.to}`))
                }}
            />
            <Stack spacing={2} direction='row'>
                <Button
                    disabled={intervalsData.hasHalfHour}
                    color='primary'
                    variant='contained'
                    className={styles.saveBtn}
                    onClick={() => {
                        onSubmit(intervalsData.intervals)
                    }}
                >
                    Сохранить
                </Button>
                {intervalsData.hasHalfHour && <span className={styles.error}>неполный час</span>}
            </Stack>
        </Stack>
    )
}

interface IProps {
    intervals: IInterval[]
    date: number
    region: ERegions
    onSubmit: (intervals: IInterval[]) => void
    onCancel: () => void
}
