import React, {useMemo} from 'react'
import cn from 'clsx'
import SettingsIcon from '@mui/icons-material/Settings'
import Button from '@mui/material/Button'

import {COLORS, ERegions, IInterval} from 'shared/consts'
import {useBookingsBrows} from 'front/queries/bookings'

import styles from './hoursTable.css'

export function HoursTable(props: IProps) {
    const {date, intervals, onClick, small, canEdit, onEdit, activeIntervals, disableIfBooked, region} = props

    const {data: bookings} = useBookingsBrows()

    const bookingForIntervals = useMemo(() => {
        const m = new Map()

        if (!bookings) {
            return m
        }

        const fBookings = bookings.filter(b => b.date === date && b.region === region)

        for (const interval of intervals) {
            for (const booking of fBookings) {
                if (booking.interval.from === interval.from || booking.interval.to === interval.to) {
                    m.set(interval, booking)
                }
            }
        }

        return m
    }, [intervals, bookings, date])

    return (
        <div className={small ? styles.hoursTableSmall : styles.hoursTable}>
            {intervals.map(item => {
                const {from, to} = item
                const fromTo = `${from}-${to}`
                const booking = bookingForIntervals.get(item)

                return (
                    <Button
                        disabled={disableIfBooked && Boolean(booking)}
                        key={fromTo}
                        color='primary'
                        variant={activeIntervals.includes(fromTo) ? 'contained' : 'outlined'}
                        style={{
                            outlineColor: COLORS.colorBrown,
                            fontWeight: 400,
                        }}
                        className={cn(styles.hourItem, booking && styles.booked)}
                        onClick={() => {
                            onClick(item)
                        }}
                    >
                        <div className={styles.timeFrom}>{from}</div>
                        <div className={styles.timeTo}>{to}</div>
                    </Button>
                )
            })}
            {canEdit && (
                <Button color='primary' variant='outlined' className={styles.settings} onClick={onEdit}>
                    <SettingsIcon />
                </Button>
            )}
        </div>
    )
}

interface IProps {
    date: number
    region: ERegions
    intervals: IInterval[]
    activeIntervals: string[]
    onClick: (interval: IInterval) => void
    onEdit?: () => void
    small?: boolean
    canEdit?: boolean
    disableIfBooked?: boolean
}
