import React from 'react'

import styles from './animation.css'

const heart = `
    M148.3,68.9c46.8-.583,74.8,19.1,91.6,48.7,4.3,7.6,6.3,16.2,10,24.8h0c2.5-6.1,4.1-12.6,6.7-18.3,
    10.5-23.5,29.2-40.2,54.1-49.5,15-5.6,39.2-7.6,57.4-3.4,52.2,11.9,97.9,57.8,84,128.6-6.5,33-22.1,
    56.1-40,77.7-26.8,32.3-62,58.3-95.3,84.1C297.1,376.9,276.6,393,261.3,413c-4.2,5.5-7.4,11.7-11.2,
    17.9h-0c-3.7-6.2-6.9-12.3-11.2-17.9-14.2-18.5-33-33.8-51.5-48.1l-55.2-44.3c-11.9-9.8-24.8-20.4-34.6-32.4l11.5,
    10.5,25.6,20.7c26.3,19.6,54.6,37.2,79.8,58a140.7,140.7,0,0,1,20.4,20.1q4.2,5.9,8.4,11.8c6.5-8.1,
    11.5-16.8,19.4-23.5,15.1-12.9,30.9-25,47.4-36.7,32.4-22.9,66.8-44.8,91.9-75.1,14.5-17.5,35.3-46.7,
    31.2-81.5-4.9-41.8-29.3-66.6-64.8-77.6-57.8-17.9-112.7,9.4-124.9,55-1.2-.954-7.9-17.4-9.7-20.3a80.3,
    80.3,0,0,0-34.9-30.6c-63-28.3-131.5,7.4-143.9,61.3-3.2,14.3-1.5,33.6,2,46l5.3,
    14.8c-5.9-10.1-10.2-22.5-13.3-35.3C33.5,141.2,68.6,95,110.8,77.3A124.1,124.1,0,0,1,136.6,70.1Z
`

const heartPath = `
    M64,249S7,99,136,88c92-6,107,85,107,85s56-154,180-47c86,94-87,212-87,212l-86,73-62-54L73,267
`

export function Animation() {
    return (
        <div className={styles.animationWrap}>
            <div className={styles.textMain}>Вы созданы, чтобы быть</div>
            <div className={styles.text1}>
                кра<span className={styles.hidden}>с</span>ивой
            </div>
            <div className={styles.text2}>
                нежно<span className={styles.hidden}>й</span>
            </div>
            <div className={styles.text3}>
                женственн<span className={styles.hidden}>о</span>й
            </div>
            <div className={styles.text4}>
                осо<span className={styles.hidden}>б</span>енной
            </div>
            <div className={styles.text5}>
                лёгк<span className={styles.hidden}>о</span>й
            </div>
            <div className={styles.letter1}>с</div>
            <div className={styles.letter2}>й</div>
            <div className={styles.letter3}>о</div>
            <div className={styles.letter4}>б</div>
            <div className={styles.letter5}>о</div>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                className={styles.heart}
                width='500'
                height='500'
                viewBox='0 0 500 500'
            >
                <clipPath id='heart-clippath'>
                    <path d={heart} />
                </clipPath>
                <path
                    clipPath='url(#heart-clippath)'
                    className={styles.heartAnimate}
                    fill='none'
                    stroke='#fffa'
                    strokeWidth='50px'
                    d={heartPath}
                />
            </svg>
        </div>
    )
}
