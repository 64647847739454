import {useMutation, useQuery, useQueryClient} from 'react-query'
import axios from 'axios'

import {IPortfolio} from 'shared/consts'
import {toggleArrayItem} from 'shared/utils'

export const usePortfolios = () => {
    return useQuery<IPortfolio[]>(['usePortfolios'], async () => {
        const portfolios = await getPortfolios()

        sortPortfolios(portfolios)

        return portfolios
    })
}

export const useAddPortfolio = () => {
    const queryClient = useQueryClient()
    const {data: portfolios} = usePortfolios()

    return useMutation(
        async (portfolio: IPortfolio) => {
            return await addPortfolio(portfolio)
        },
        {
            onSuccess: (res, portfolio) => {
                queryClient.setQueryData(
                    ['usePortfolios'],
                    [
                        ...portfolios,
                        {
                            ...res,
                            ...portfolio,
                        },
                    ],
                )
            },
        },
    )
}

export const useUpdatePortfolio = () => {
    const queryClient = useQueryClient()
    const {data: portfolios} = usePortfolios()

    return useMutation(
        async (portfolio: Partial<IPortfolio>) => {
            return await updatePortfolio(portfolio)
        },
        {
            onSuccess: (res, portfolio) => {
                const index = portfolios.findIndex(item => item.id === portfolio.id)
                const newPortfolios = [...portfolios]

                newPortfolios[index] = {
                    ...portfolios[index],
                    ...portfolio,
                }

                sortPortfolios(newPortfolios)

                queryClient.setQueryData(['usePortfolios'], newPortfolios)
            },
        },
    )
}

export const useAddImages = () => {
    const queryClient = useQueryClient()
    const {data: portfolios} = usePortfolios()

    return useMutation(
        async (data: {portfolioId: number; images: FileList}) => {
            return await addImages(data.portfolioId, data.images)
        },
        {
            onSuccess: (res, data) => {
                const {portfolioId} = data
                const index = portfolios.findIndex(item => item.id === portfolioId)
                const newPortfolios = [...portfolios]

                newPortfolios[index] = {
                    ...portfolios[index],
                    images: [...portfolios[index].images, ...res],
                }

                queryClient.setQueryData(['usePortfolios'], newPortfolios)
            },
        },
    )
}

export const useDeleteImage = () => {
    const queryClient = useQueryClient()
    const {data: portfolios} = usePortfolios()

    return useMutation(
        async (data: {id: number; portfolioId: number}) => {
            return await deleteImage(data)
        },
        {
            onSuccess: (res, data) => {
                const {id, portfolioId} = data
                const index = portfolios.findIndex(item => item.id === portfolioId)
                const newPortfolios = [...portfolios]
                const images = toggleArrayItem(portfolios[index].images, id, false)

                newPortfolios[index] = {
                    ...portfolios[index],
                    images,
                }

                queryClient.setQueryData(['usePortfolios'], newPortfolios)
            },
        },
    )
}

export const useDeletePortfolio = () => {
    const queryClient = useQueryClient()
    const {data: portfolios} = usePortfolios()

    return useMutation(
        async (id: number) => {
            return await deletePortfolio(id)
        },
        {
            onSuccess: (res, id) => {
                queryClient.setQueryData(
                    ['usePortfolios'],
                    portfolios.filter(item => item.id !== id),
                )
            },
        },
    )
}

function sortPortfolios(portfolios: IPortfolio[]) {
    portfolios.sort((a, b) => {
        return a.orderId - b.orderId
    })
}

const getPortfolios = async (): Promise<IPortfolio[]> => {
    const {data} = await axios({
        method: 'get',
        url: '/api/v1/portfolio',
    })

    return data
}

const addPortfolio = async (params: IPortfolio): Promise<{id: number}> => {
    const {data} = await axios({
        method: 'post',
        url: '/api/v1/portfolio',
        data: params,
    })

    return data
}

const updatePortfolio = async (portfolio: Partial<IPortfolio>): Promise<void> => {
    const {data} = await axios({
        method: 'post',
        url: `/api/v1/portfolio/${portfolio.id}`,
        data: portfolio,
    })

    return data
}

const deletePortfolio = async (id: number) => {
    await axios({
        method: 'delete',
        url: `/api/v1/portfolio/${id}`,
    })
}

const addImages = async (id: number, images: FileList): Promise<number[]> => {
    const formData = new FormData()

    for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i])
    }

    formData.append('portfolio', String(id))

    const {data} = await axios({
        method: 'post',
        url: '/api/v1/images',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return data
}

const deleteImage = async (params: {id: number; portfolioId: number}) => {
    await axios({
        method: 'delete',
        url: `/api/v1/images/${params.id}`,
        data: params,
    })
}
