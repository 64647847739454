import React, {useMemo} from 'react'
import {IMaskInput} from 'react-imask'
import {InputBaseComponentProps} from '@mui/material/InputBase'
import TextField from '@mui/material/TextField'

import {getPhoneMask} from 'shared/utils'
import {useCountry} from 'front/hooks/useCountry'

import styles from './phone.css'

export function Phone(props: IProps) {
    const {phone, setPhone, disabled, autoFocus} = props
    const country = useCountry()

    const PhoneNumberFormat = useMemo(() => {
        return React.forwardRef((props: InputBaseComponentProps, ref: (instance: HTMLInputElement) => void) => {
            return (
                <IMaskInput
                    {...props}
                    mask={getPhoneMask(country)}
                    /* eslint-disable-next-line */
                    prepare={(value: string, masked: any) => {
                        if (masked._value === '+7 (' && ['7', '8'].includes(value)) {
                            return
                        }

                        if (masked._value === '+357-' && ['3', '5', '7'].includes(value)) {
                            return
                        }

                        return value
                    }}
                    lazy={false}
                    unmask
                    inputRef={ref}
                    onAccept={(value: string) => {
                        setPhone(value)
                    }}
                />
            )
        })
    }, [setPhone])

    return (
        <TextField
            autoFocus={autoFocus}
            value={phone}
            disabled={disabled}
            className={styles.phone}
            color='primary'
            required
            label='Телефон'
            variant='outlined'
            size='small'
            InputProps={{
                inputComponent: PhoneNumberFormat,
            }}
        />
    )
}

interface IProps {
    setPhone: (phone: string) => void
    phone: string
    disabled?: boolean
    autoFocus?: boolean
}
